<div class="container">
    <form class="row" [formGroup]="headerForm" (ngSubmit)="onSubmit(headerForm)">
        <div class="col-md-8 offset-md-2 p-4 mt-2">
            <div class="border shadow">
                <div class="m-2 row">
                    <div class="col-md-12">
                        <label class="col-form-label">Heading</label>
                        <div>
                            <input class="form-control" placeholder="Enter Heading" formControlName="heading"
                                [ngClass]="{'invalid': headerForm.get('heading').invalid && isSubmitted}">
                        </div>
                    </div>
                </div>
                <div class="m-2 row">
                    <div class="col-md-12">
                        <label class="col-form-label">Sub Heading</label>
                        <div>
                            <input class="form-control" placeholder="Enter Sub Heading" formControlName="sub_heading"
                                [ngClass]="{'invalid': headerForm.get('sub_heading').invalid && isSubmitted}">
                        </div>
                    </div>
                </div>
                <div class="m-2 row">
                    <div class="col-md-12">
                        <label class="col-form-label">Header Image URL</label>
                        <div class="d-flex">
                            <input type="text" class="form-control" placeholder="URL"
                                formControlName="header_image_url" />
                            <a [href]="headerForm.value.header_image_url" target="_blank">
                                <img [src]="headerForm.value.header_image_url" class="ms-1" height="40" />
                            </a>
                            <button class="btn btn-primary ms-1" type="button"
                                (click)="openUploadModal('header_image_url')">
                                <i class="fas fa-cloud-upload-alt"></i>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="mx-2 my-4 row">
                    <div class="col-md-12">
                        <button class="btn btn-info float-end" [disabled]="!hasChanges"><i
                                [ngClass]="{'fa fa-pulse fa-spin fa-spinner': isUpdating,'bx bx-save': !isUpdating}"></i>
                            Save Changes</button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>