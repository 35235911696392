import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SharedService } from 'src/app/core/services/shared.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-upload-gallery',
  templateUrl: './upload-gallery.component.html',
  styleUrls: ['./upload-gallery.component.scss']
})
export class UploadGalleryComponent {

  @Input() uploadDetails;
  galleryList = [];
  selectedImage: any;
  selectedIndex: number;
  fileToUpload: File | null = null;
  isLoading = false;
  isUpdating = false;

  imageForm: FormGroup

  imageWidths: number[] = [];
  imageHeights: number[] = [];
  isUploading: boolean;
  progress: number;

  pageSize = 18;
  pageNumber = 1;
  page_details: any = {
    count: 0,
    next: '',
    previous: ''
  };

  constructor(public activeModal: NgbActiveModal, public api: SharedService,
    private fb: FormBuilder, public toastr: ToastrService) { }

  closeModal(status, image?) {
    this.activeModal.close({
      status: status,
      image: image?.document,
      id: image?.id
    });
  }

  ngOnInit() {
    this.resetForm();
    this.getImages(this.pageSize, 0);
  }

  getImages(limit, offset) {
    this.isLoading = true;
    this.api.getGallery(limit, offset).subscribe(
      (res => {
        this.galleryList = res.results;
        this.page_details = {
          count: res.count,
          next: res.next,
          previous: res.previous
        };
        this.isLoading = false;
      }),
      (() => {
        this.isLoading = false;
      })
    )
  }

  resetForm(data?) {
    this.imageForm = this.fb.group({
      document: [data ? data.document : '', [Validators.required]],
      alt_text: [data ? data.alt_text : ''],
      common_file: [data ? data.id : null],
      title: [data ? data.title : ''],
      caption: [data ? data.caption : ''],
      description: [data ? data.description : '']
    })
  }

  openImageInNewTab(imageUrl: string) {
    window.open(imageUrl, '_blank');
  }

  selectImage(image: any, selectedIndex: number) {

    this.resetForm(image);

    this.galleryList.forEach((image, index) => {
      image.selected = index === selectedIndex;
    });
    this.selectedImage = image;
    this.selectedIndex = selectedIndex;
  }

  getImageDetails(event: Event, index: number) {
    const imgElement = event.target as HTMLImageElement;
    this.imageWidths[index] = imgElement.width;
    this.imageHeights[index] = imgElement.height;
  }

  setImage() {
    this.closeModal(true, this.selectedImage);
  }

  handleIcon(files: FileList) {
    this.isUploading = true;
    this.fileToUpload = files.item(0);

    const formData: FormData = new FormData();
    formData.append('document', this.fileToUpload);

    this.api.uploadFile(formData).subscribe(
      (event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.UploadProgress:
            this.progress = Math.round(100 * event.loaded / event.total);
            break;
          case HttpEventType.Response:
            this.progress = 0;
            this.galleryList.forEach((image) => {
              image.selected = false;
            });
            // this.selectedImage = { document: event.body.document, selected: true };
            // this.galleryList.push(this.selectedImage);
            // this.selectedIndex = 0;
            this.getImages(this.pageSize, 0);
            break;
        }
      }
    );
  }

  onSubmit(form: FormGroup) {
    this.isUpdating = true;
    this.api.galleryDetails(form.value).subscribe(
      (response: any) => {
        this.isUpdating = false;
        this.galleryList.forEach(ae => {
          if (ae.id == form.value.common_file) {
            ae.alt_text = response.alt_text;
            ae.common_file = response.common_file;
            ae.title = response.title;
            ae.caption = response.caption;
            ae.description = response.description;
          }
        });
        this.toastr.success("Details Updated Successfully!");
      },
      () => {
        this.isUpdating = false;
      }
    )
  }

  triggerClick(id) {
    let element: HTMLElement = document.getElementById(id) as HTMLElement;
    element.click();
  }

  nextPage(page) {
    this.getImages(this.pageSize, this.pageSize * (page - 1));
  }
}
