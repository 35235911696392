<div class="container-fluid">

    <div class="my-3 row">
        <div class="offset-md-8 col-md-4">
            <ng-select [(items)]="countryList" [multiple]="false" [placeholder]="'Select Country'" bindLabel="name"
                bindValue="id" [(ngModel)]="country" [clearable]="false" (change)="selectCountry(country)"></ng-select>
        </div>
    </div>
    <div class="my-3 row">
        <div class="col-md-4">
            <div class="my-2 row">
                <div class="col-md-12">
                    <label class="col-form-label">Category</label>
                    <ng-select [(items)]="categoryList" [multiple]="false" [placeholder]="'Select Product'"
                        bindLabel="name" bindValue="id" [(ngModel)]="category" [clearable]="false"></ng-select>
                </div>
            </div>
        </div>
        <div class="col-md-8">
            <div class="my-2 row">
                <div class="col-md-12">
                    <label class="col-form-label">Image</label>
                    <div class=" d-flex">
                        <input type="text" class="form-control" placeholder="Image Url" [(ngModel)]="imageUrl">
                        <a [href]="imageUrl" target="_blank">
                            <img class="ms-1" height="40" [src]="imageUrl">
                        </a>
                        <button class="btn btn-primary ms-1" type="button" (click)="openUploadModal()"><i
                                class="fas fa-cloud-upload-alt"></i></button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="w-100 text-center">
        <button class="btn btn-primary mt-1 me-1" (click)="updateImage()"><i
                [ngClass]="{'fa fa-pulse fa-spin fa-spinner': isUpdating,'bx bx-save': !isUpdating}"></i>
            {{imageUrl ? 'Update Image' : 'Add Image'}}</button>
    </div>

</div>