<div class="container-fluid">
  <!-- start page title -->
  <app-page-title title="Category Page" [breadcrumbItems]="breadCrumbItems"></app-page-title>

  <div class="my-3 row">
    <div class="offset-md-4 col-md-4">
      <ng-select [(items)]="countryList" [multiple]="false" [placeholder]="'Select Country'" bindLabel="name"
        bindValue="id" [(ngModel)]="country" [clearable]="false" (change)="selectCountry(country)"></ng-select>
    </div>
    <div class="col-md-4">
      <ng-select [(items)]="categoryList" [multiple]="false" [placeholder]="'Select Category'" bindLabel="name"
        bindValue="id" [(ngModel)]="category" [clearable]="false" (change)="selectCategory(category)"></ng-select>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-8 offset-lg-2 set-minwh-1" *ngIf="country==null || category==null;">
      <div class="card text-center p-4">
        Select {{country ? '' : 'Country &'}} {{category ? '': 'Category'}} to Continue
      </div>
    </div>
    <div class="col-12" *ngIf="country && category">
      <div class="card">
        <div class="card-body">
          <app-preview-btn [page]="page"></app-preview-btn>
          <ul ngbNav #justifiednavpills="ngbNav" [activeId]="2" class="nav-pills nav-justified bg-light">
            <li [ngbNavItem]="2">
              <a ngbNavLink>
                <span>Header</span>
              </a>
              <ng-template ngbNavContent>
                <app-category-header [country]="country" [category]="category" [page]="page"></app-category-header>
              </ng-template>
            </li>
            <li [ngbNavItem]="3">
              <a ngbNavLink>
                <span>Eligibility</span>
              </a>
              <ng-template ngbNavContent>
                <app-eligibility [country]="country" [category]="category" [page]="page"></app-eligibility>
              </ng-template>
            </li>
            <li [ngbNavItem]="7">
              <a ngbNavLink>
                <span>Required&nbsp;Documents</span>
              </a>
              <ng-template ngbNavContent>
                <app-required-docs [country]="country" [category]="category" [page]="page"></app-required-docs>
              </ng-template>
            </li>
            <li [ngbNavItem]="8">
              <a ngbNavLink>
                <span>Additional&nbsp;Info</span>
              </a>
              <ng-template ngbNavContent>
                <app-additional-info [country]="country" [category]="category" [page]="page"></app-additional-info>
              </ng-template>
            </li>
            <li [ngbNavItem]="9">
              <a ngbNavLink>
                <span>About&nbsp;Visa</span>
              </a>
              <ng-template ngbNavContent>
                <app-about-visa [country]="country" [category]="category" [page]="page"></app-about-visa>
              </ng-template>
            </li>
            <li [ngbNavItem]="10">
              <a ngbNavLink>
                <span>Snackbar</span>
              </a>
              <ng-template ngbNavContent>
                <app-snackbar [country]="country" [category]="category" [page]="page"></app-snackbar>
              </ng-template>
            </li>
          </ul>
          <div [ngbNavOutlet]="justifiednavpills"></div>
        </div>
      </div>
    </div>
  </div>
</div>