<div class="container">
    <form class="row" [formGroup]="headerForm" (ngSubmit)="onSubmit(headerForm)">
        <div class="col-md-10 offset-md-1 p-4 mt-2">
            <div class="border shadow p-4">
                <div class="mt-2 row">
                    <div class="col-md-12">
                        <label class="col-form-label">Heading</label>
                        <input type="text" class="form-control" placeholder="Enter Sub Heading"
                            formControlName="heading"
                            [ngClass]="{'invalid': headerForm.get('heading').invalid && isSubmitted}">
                    </div>
                </div>
                <div class="mt-2 row">
                    <div class="col-md-12">
                        <label class="col-form-label">Sub Heading</label>
                        <input type="text" class="form-control" placeholder="Enter Sub Heading"
                            formControlName="sub_heading"
                            [ngClass]="{'invalid': headerForm.get('sub_heading').invalid && isSubmitted}">
                    </div>
                </div>
                <div class="row">
                    <!-- <div class="mt-2 col-md-12">
                        <label class="col-form-label">Banner Image URL</label>
                        <div class="d-flex">
                            <input type="text" class="form-control" placeholder="URL"
                                formControlName="country_banner_image_url">
                            <a [href]="headerForm.value.country_banner_image_url" target="_blank"><img class="ms-1"
                                    height="40" [src]="headerForm.value.country_banner_image_url"></a>
                            <button class="btn btn-primary ms-1" type="button"
                                (click)="openUploadModal('country_banner_image_url')"><i
                                    class="fas fa-cloud-upload-alt"></i></button>
                        </div>
                    </div> -->

                    <!-- <div class="mt-2 col-md-12">
                        <label class="col-form-label">Banner Image URL for Mobile</label>
                        <div class="d-flex">
                            <input type="text" class="form-control" placeholder="URL"
                                formControlName="country_mob_banner_image_url">
                            <a [href]="headerForm.value.country_mob_banner_image_url" target="_blank"><img class="ms-1"
                                    height="40" [src]="headerForm.value.country_mob_banner_image_url"></a>
                            <button class="btn btn-primary ms-1" type="button"
                                (click)="openUploadModal('country_mob_banner_image_url')"><i
                                    class="fas fa-cloud-upload-alt"></i></button>
                        </div>
                    </div> -->

                    <div class="my-2 row">
                        <div class="col-md-6">
                            <label class="col-form-label">Center Image URL</label>
                            <div class=" d-flex">
                                <input type="text" class="form-control" placeholder="URL"
                                    formControlName="banner_image_url">
                                <a [href]="headerForm.value.banner_image_url" target="_blank"><img class="ms-1"
                                        height="40" [src]="headerForm.value.banner_image_url"></a>
                                <button class="btn btn-primary ms-1" type="button"
                                    (click)="openUploadModal('banner_image_url')"><i
                                        class="fas fa-cloud-upload-alt"></i></button>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <label class="col-form-label">Center Image URL for Mobile</label>
                            <div class=" d-flex">
                                <input type="text" class="form-control" placeholder="URL"
                                    formControlName="mob_banner_image_url">
                                <a [href]="headerForm.value.mob_banner_image_url" target="_blank"><img class="ms-1"
                                        height="40" [src]="headerForm.value.mob_banner_image_url"></a>
                                <button class="btn btn-primary ms-1" type="button"
                                    (click)="openUploadModal('mob_banner_image_url')"><i
                                        class="fas fa-cloud-upload-alt"></i></button>
                            </div>
                        </div>
                        <!-- <div class="col-md-6">
                            <label class="col-form-label">Visa Pro Icon URL</label>
                            <div class=" d-flex">
                                <input type="text" class="form-control" placeholder="URL"
                                    formControlName="sub_heading_icon_url">
                                <a [href]="headerForm.value.sub_heading_icon_url" target="_blank"><img class="ms-1"
                                        height="40" [src]="headerForm.value.sub_heading_icon_url"></a>
                                <button class="btn btn-primary ms-1" type="button"
                                    (click)="openUploadModal('sub_heading_icon_url')"><i
                                        class="fas fa-cloud-upload-alt"></i></button>
                            </div>
                        </div> -->
                    </div>
                    <!-- <div class="my-2 row">
                        <div class="col-md-4">
                            <label class="col-form-label">Banner Button URL</label>
                            <div class="d-flex">
                                <input type="text" class="form-control" placeholder="Banner Url"
                                    formControlName="country_banner_button_url">
                            </div>
                        </div>

                        <div class="col-md-4">
                            <label class="col-form-label">Count on Us Button URL</label>
                            <div class="d-flex">
                                <input type="text" class="form-control" placeholder="Button Url"
                                    formControlName="count_on_us_url">
                            </div>
                        </div>

                        <div class="col-md-4">
                            <label class="col-form-label">FAQ Button URL</label>
                            <div class="d-flex">
                                <input type="text" class="form-control" placeholder="Button Url"
                                    formControlName="faq_url">
                            </div>
                        </div>
                    </div> -->
                </div>
                <div class="mt-2 row">
                    <div class="col-md-12">
                        <button
                            *ngIf="(page=='country' && checkPermission('content_management_country_create'))&&(page=='country' && checkPermission('content_management_country_edit'))"
                            class="btn btn-info float-end"><i *ngIf="isUpdating"
                                class="fa fa-spin fa-spinner fa-pulse"></i> Save Changes</button>
                        <app-publish-btn
                            *ngIf="(page=='country' && checkPermission('content_management_country_publish'))"
                            class="float-end me-2" [publish]="publishPage" [page]="page"></app-publish-btn>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>