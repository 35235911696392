<div class="container-fluid">
    <div class="my-3 row" cdkDropList (cdkDropListDropped)="drop($event)">
        <div class="offset-md-2 col-md-8 bg-white" *ngFor="let item of categoriesList; let i=index;" cdkDrag>
            <div class="cat-card border mb-2 row">
                <span class="col-md-4">{{item.category_name}}</span>
                <span class="col-md-4">
                    <div class="form-check form-switch form-switch-lg m-auto float-end" dir="ltr">
                        <label class="col-form-label cpr" for="customPriority{{i}}">Show in Web</label>
                        <input type="checkbox" [(ngModel)]="item.show_web" (change)="changeStatus(item)"
                            class="form-check-input mt-2" id="customPriority{{i}}">
                    </div>
                </span>
                <span class="col-md-4 text-end">
                    <div class="example-handle float-end cpr" cdkDragHandle>
                        <i class="mdi mdi-cursor-move"></i>
                    </div>
                </span>
            </div>
        </div>
    </div>
</div>