import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SharedService } from 'src/app/core/services/shared.service';
import { UploadGalleryComponent } from 'src/app/shared/ui/upload-gallery/upload-gallery.component';
import { V3ServicesService } from 'src/app/core/services/v3-services.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-home-visa-kind',
  templateUrl: './home-visa-kind.component.html',
  styleUrls: ['./home-visa-kind.component.scss']
})
export class HomeVisaKindComponent {

  country = this.sharedService.selectedCmsCountryId;
  countryList = this.sharedService.cmsCountryList;
  imageUrl: any;
  imageId: number;
  isUpdating: boolean;
  categoryList: any;
  category;

  constructor(private sharedService: SharedService,
    private api: V3ServicesService,
    private modalService: NgbModal,
    private toastr: ToastrService,) { }

  ngOnInit() {
    if (this.countryList.length == 0) this.getCountriesList();
  }

  getCountryImage(country) {
    this.api.getHomeTrending(country).subscribe((result: any) => {
      this.imageId = result[0]?.id;
      this.imageUrl = result[0]?.image;
      this.category = result[0]?.category;
    });
  }

  selectCountry(country) {
    this.country = country;
    this.category = null;
    this.getCountryImage(this.country);
    this.getCategories(this.country);
  }

  getCountriesList() {
    this.sharedService.getCmsCountryDetails().subscribe(
      (response: any) => {
        this.countryList = response;
        this.sharedService.cmsCountryList = this.countryList;
        if (!this.country) this.country = this.countryList[0]?.id;
        this.getCountryImage(this.country);
        this.getCategories(this.country);
      })
  }

  getCategories(country_id) {
    this.sharedService.getCmsCategoryList(country_id).subscribe(
      (result: any) => {
        this.categoryList = result;
      })
  }

  openUploadModal() {
    const modalRef = this.modalService.open(UploadGalleryComponent,
      {
        size: 'xl',
        scrollable: true,
        windowClass: 'modal-big-lg',
        keyboard: false,
        backdrop: 'static'
      });
    modalRef.componentInstance.uploadDetails = {};
    modalRef.result.then((result) => {
      if (result.status) {
        this.imageUrl = result.image;
      }
    });
  }

  updateImage() {
    this.isUpdating = true;

    if (this.imageId) {
      this.api.updateHomeTrending(this.imageId, {
        country: this.country,
        category: this.category,
        image: this.imageUrl,
      }).subscribe(
        () => {
          this.toastr.success("Image Updated Successfully!");
          this.getCountryImage(this.country);
          this.isUpdating = false;
        },
        () => {
          this.isUpdating = false;
        })
    } else {
      this.api.setHomeTrending({
        country: this.country,
        category: this.category,
        image: this.imageUrl,
      }).subscribe(
        () => {
          this.toastr.success("Image Added Successfully!");
          this.getCountryImage(this.country);
          this.isUpdating = false;
        },
        () => {
          this.isUpdating = false;
        })
    }
  }
}
