import { Component } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from "ngx-toastr";
import { ProductService } from "src/app/core/services/product.service";
import { ProductV2Service } from "src/app/core/services/product-v2.service";
import { SharedService } from "src/app/core/services/shared.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { UploadGalleryComponent } from "src/app/shared/ui/upload-gallery/upload-gallery.component";


@Component({
  selector: 'app-payment-benefits',
  templateUrl: './payment-benefits.component.html',
  styleUrls: ['./payment-benefits.component.scss']
})
export class PaymentBenefitsComponent {
  benefitsForm: FormGroup;
  isUpdated: boolean = false;
  typeId: any;
  selected = false;
  deletedPaybenefits: any = [];

  constructor(
    private sharedService: SharedService,
    private api: ProductService,
    private apiv2: ProductV2Service,
    private fb: FormBuilder,
    private tosatr: ToastrService,
    private modalService: NgbModal
  ) {
    this.benefitsForm = this.fb.group({
      paybenefits: this.fb.array([])
    });
  }

  ngOnInit(): void {
    this.getPayBenefits();
  }

  initializePaybenefits(paybenefits: any[]): void {
    const paybenefitsArray = this.benefitsForm.get('paybenefits') as FormArray;
    if (paybenefits.length > 0) {
      paybenefits.forEach(benefit => {
        paybenefitsArray.push(this.createPaybenefitsGroup(benefit));
      });
    } else {
      paybenefitsArray.push(this.createEmptyPaybenefitsGroup());
    }
    this.deletedPaybenefits = [];
  }

  createPaybenefitsGroup(benefit: any): FormGroup {
    return this.fb.group({
      id: [benefit.id],
      heading: [benefit.heading, Validators.required],
      benefits: this.fb.array(benefit.benefits.map((benefit: string) => this.fb.control(benefit, Validators.required))),
      tags: this.fb.array(
        benefit.tags.map((tag: any) =>
          this.fb.group({
            text: [tag.text, Validators.required],
            icon: [tag.icon, Validators.required],
            is_updated: [false],
            is_new: [false],
          })
        )
      ),
      link: [benefit.link, Validators.required],
      link_text: [benefit.link_text, Validators.required],
      icon: [benefit.icon, Validators.required],
      is_new: [false],
      is_updated: [false]
    });
  }


  createEmptyPaybenefitsGroup(): FormGroup {
    return this.fb.group({
      heading: ['', Validators.required],
      benefits: this.fb.array([this.fb.control('', Validators.required)]),
      link: ['', Validators.required],
      link_text: ['', Validators.required],
      tags: this.fb.array([
        this.fb.group({
          text: ['', Validators.required],
          icon: ['', Validators.required],
          is_new: [true, Validators.required],
          is_updated: [false, Validators.required],
        })
      ]),
      icon: ['', Validators.required],
      is_new: [true],
      is_updated: [false]
    });
  }

  get paybenefits() {
    return this.benefitsForm.get('paybenefits') as FormArray;
  }

  paybenefitsFb(): FormArray {
    return this.benefitsForm.get('paybenefits') as FormArray;
  }

  getBenefits(benefitIndex: number) {
    return (this.paybenefits.at(benefitIndex).get('benefits') as FormArray).controls;
  }

  getTags(benefitIndex: number): FormArray {
    return (this.paybenefits.at(benefitIndex).get('tags') as FormArray);
  }

  addBenefit(benefitIndex: number) {
    const benefitsArray = this.paybenefits.at(benefitIndex).get('benefits') as FormArray;
    benefitsArray.push(this.fb.control('', Validators.required));
  }

  removeBenefit(index: number, benefitIndex: number) {
    const benefitsArray = this.paybenefits.at(index).get('benefits') as FormArray;
    benefitsArray.removeAt(benefitIndex);
  }

  addTag(benefitIndex: number): void {
    const tagsArray = this.getTags(benefitIndex);
    tagsArray.push(
      this.fb.group({
        text: ['', Validators.required],
        icon: ['', Validators.required],
        is_updated: [false],
        is_new: [true]
      })
    );
  }

  removeTag(benefitIndex: number, tagIndex: number): void {
    const tagsArray = this.getTags(benefitIndex);
    tagsArray.removeAt(tagIndex);
  }


  getPayBenefits() {
    this.apiv2.getPayBenefitDetails().subscribe((res) => {
      this.benefitsForm = this.fb.group({
        paybenefits: this.fb.array([])
      });
      this.initializePaybenefits(res || []);
    });
  }

  addPaybenefits() {
    const paybenefitsArray = this.benefitsForm.get('paybenefits') as FormArray;
    paybenefitsArray.push(this.createEmptyPaybenefitsGroup());
  }

  openUploadModal(field, i, j?) {
    const modalRef = this.modalService.open(UploadGalleryComponent,
      {
        size: 'xl',
        scrollable: true,
        windowClass: 'modal-big-lg',
        keyboard: false,
        backdrop: 'static'
      });
    modalRef.componentInstance.uploadDetails = {};
    modalRef.result.then((result) => {
      if (result.status) {
        if (j >= 0) {
          let payArray = this.benefitsForm.get("paybenefits") as FormArray;
          let tagsArray = (payArray.at(i).get("tags") as FormArray).at(j);
          let imageValue = tagsArray.get(field).value;
          if (imageValue) {
            tagsArray.get("is_updated").setValue(true);
          }
          tagsArray.get(field).setValue(result.image);
        } else {
          this.benefitsForm.get(['paybenefits', i, field]).setValue(result.image);
          this.benefitsForm.value.paybenefits[i].is_updated = true;
        }
      }
    });
  }

  submitPayBenefits() {
    if (!this.benefitsForm.valid) {
      this.benefitsForm.markAllAsTouched();
      this.tosatr.warning("Fill All Fields!");
      return;
    }

    const paybenefitsList = [
      ...this.benefitsForm.value.paybenefits.map(benefit => ({
        ...benefit,
        is_updated: !benefit.is_new ? benefit.is_updated : undefined,
        is_new: benefit.is_new ? true : undefined,
      })),
      ...this.deletedPaybenefits
    ];


    this.apiv2.setPayBenefitDetails(paybenefitsList).subscribe({
      next: () => {
        this.tosatr.success("Success", "Payment Benefits Details Updated successfully");
        this.getPayBenefits();
      },
      error: (err) => {
        console.error(err);
      },
    });
  }


  removePaybenefits(i: number) {
    const paybenefitsArray = this.benefitsForm.get('paybenefits') as FormArray;
    this.benefitsForm.value.paybenefits[i].is_deleted = true;
    if (this.benefitsForm.value.paybenefits[i].hasOwnProperty("id")) this.deletedPaybenefits.push(this.benefitsForm.value.paybenefits[i]);

    // Ensure there's at least one form remaining
    if (paybenefitsArray.length > 1) {
      paybenefitsArray.removeAt(i);
    } else {
      this.tosatr.warning('At least one benefit must be present.');
    }
  }

  datachanged(i) {
    this.benefitsForm.get(['paybenefits', i, 'is_updated']).setValue(true);
  }

}
