import { Injectable } from '@angular/core';
import { retry } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class OtherPagesService {

  constructor(private http: HttpClient) { }

  // Consultant Service
  getConsultancyData(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/cms-admin/home/consultancy-service/`).pipe(retry(2));
  }

  setConsultancyData(id, data): Observable<any> {
    return this.http.put(`${environment.apiUrl}/cms-admin/home/consultancy-service/${id}/`, data);
  }

  postConsultancyData(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/cms-admin/home/consultancy-service/`, data);
  }

  getApplyStepsConsultant(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/cms-admin/home/consultant-application-step/`).pipe(retry(2));
  }

  setApplyStepsConsultant(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/cms-admin/home/consultant-application-step/`, data);
  }


  getVisaProDescription(id?): Observable<any> {
    return this.http.get(`${environment.apiUrl}/cms-admin/home/consultant-application-process/`).pipe(retry(2));
  }

  setVisaProDescription(id, data): Observable<any> {
    return this.http.put(`${environment.apiUrl}/cms-admin/home/consultant-application-process/${id}/`, data);
  }

  postVisaProDescription(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/cms-admin/home/consultant-application-process/`, data);
  }

  // Testimonial Header
  getTestimonialData(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/cms-admin/testimonial/testimonial-description/`).pipe(retry(2));
  }

  setTestimonialData(id, data): Observable<any> {
    return this.http.put(`${environment.apiUrl}/cms-admin/testimonial/testimonial-description/${id}/`, data);
  }

  postTestimonialData(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/cms-admin/testimonial/testimonial-description/`, data);
  }

  // Faq Header
  getFaqDescription(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/cms-admin/faq/faq-description/`).pipe(retry(2));
  }

  setFaqDescription(id, data): Observable<any> {
    return this.http.put(`${environment.apiUrl}/cms-admin/faq/faq-description/${id}/`, data);
  }

  postFaqDescription(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/cms-admin/faq/faq-description/`, data);
  }


  // Faq Header
  getConsultantDescription(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/cms-admin/home/consultancy-description/`).pipe(retry(2));
  }

  setConsultantDescription(id, data): Observable<any> {
    return this.http.put(`${environment.apiUrl}/cms-admin/home/consultancy-description/${id}/`, data);
  }

  postConsultantDescription(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/cms-admin/home/consultancy-description/`, data);
  }

  getDeleteCountryData(id) {
    return this.http.get(`${environment.apiUrl}/cms-admin/common/${id}/country-management/`).pipe(retry(2));
  }

  getDeleteCategoryData(id) {
    return this.http.get(`${environment.apiUrl}/cms-admin/common/${id}/category-management/`).pipe(retry(2));
  }

  getDeleteProductData(id) {
    return this.http.get(`${environment.apiUrl}/cms-admin/common/${id}/product-management/`).pipe(retry(2));
  }

  deleteCountryData(id): Observable<any> {
    return this.http.delete(`${environment.apiUrl}/cms-admin/common/${id}/country-management/`);
  }

  deleteCategoryData(id): Observable<any> {
    return this.http.delete(`${environment.apiUrl}/cms-admin/common/${id}/category-management/`);
  }

  deleteProductData(id): Observable<any> {
    return this.http.delete(`${environment.apiUrl}/cms-admin/common/${id}/product-management/`);
  }

  deleteCMSCountryData(id): Observable<any> {
    return this.http.delete(`${environment.apiUrl}/cms-admin/common/${id}/cms-country-datas/`);
  }

  // Interactive Banner
  getInteractiveBanner(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/interactive-banners/`).pipe(retry(2));
  }

  setInteractiveBanner(id, data): Observable<any> {
    return this.http.put(`${environment.apiUrl}/interactive-banners/${id}/`, data);
  }

  postInteractiveBanner(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/interactive-banners/`, data);
  }
}
