import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import { HomeManagementService } from 'src/app/core/services/home-management.service';
import { ToastrService } from 'ngx-toastr';
import { PermissionService } from 'src/app/core/services/permision.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UploadGalleryComponent } from 'src/app/shared/ui/upload-gallery/upload-gallery.component';

@Component({
  selector: 'app-partners',
  templateUrl: './partners.component.html',
  styleUrls: ['./partners.component.scss']
})
export class PartnersComponent implements OnInit {

  partnerForm: FormGroup;
  hasChanges = false;

  isUpdating = false;
  deletedpartners = [];
  isSubmitted = false;
  @Input() page;

  constructor(private fb: FormBuilder, private api: HomeManagementService, public permission: PermissionService,
    private modalService: NgbModal, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.resetForm();
    this.getpartners();
  }

  resetForm() {
    this.partnerForm = this.fb.group({
      partners: this.fb.array([
        this.fb.group({
          id: ['', [Validators.required]],
          icon_url: ['', [Validators.required]],
          is_starred: [false, [Validators.required]],
          priority: [1, [Validators.required]],
          is_priority: [false, [Validators.required]],
          is_updated: [''],
        })])
    });
  }

  getpartners() {
    this.api.getTrustedPartners().subscribe(
      (result: any) => {
        this.partnerForm = this.fb.group({
          partners: new FormArray([]),
        });
        result.forEach(ao => {
          (this.partnerForm.get('partners') as FormArray).push(
            this.fb.group({
              id: [ao.id, [Validators.required]],
              icon_url: [ao.icon_url, [Validators.required]],
              priority: [ao.priority, [Validators.required]],
              is_priority: [ao.is_priority, [Validators.required]],
              is_starred: [ao.is_starred, [Validators.required]],
              is_updated: [false],
            })
          );
          this.deletedpartners = [];
        });
      })
  }

  partners(): FormArray {
    return this.partnerForm.get("partners") as FormArray
  }

  newpartners(): FormGroup {
    return this.fb.group({
      icon_url: ['', [Validators.required]],
      is_starred: [false, [Validators.required]],
      priority: [1, [Validators.required]],
      is_priority: [false, [Validators.required]],
      is_updated: ['']
    })
  }

  addpartners() {
    this.partners().push(this.newpartners());
  }

  removepartners(i: number) {
    this.isSubmitted = false;
    this.partnerForm.value.partners[i].is_deleted = true;
    if (this.partnerForm.value.partners[i].hasOwnProperty("id")) this.deletedpartners.push(this.partnerForm.value.partners[i]);
    this.partners().removeAt(i);
  }

  onSubmit(form) {
    if (form.valid) {
      this.isUpdating = true;
      this.deletedpartners.forEach(ae => {
        ae.is_updated = false;
        form.value.partners.push(ae);
      })
      this.api.setPartners(form.value.partners).subscribe(
        () => {
          this.toastr.success("Content Updated Successfully!");
          this.getpartners();
          this.hasChanges = false;
          this.isUpdating = false;
        },
        () => {
          this.isUpdating = false;
        }
      )
    } else {
      this.isSubmitted = true;
      this.toastr.warning("Fill all fields and Save!");
    }
  }

  dataChanged(i) {
    this.partnerForm.value.partners[i].is_updated = true;
  }

  checkPermission(type) {
    return this.permission.hasPermission(type);
  }

  openUploadModal(i) {
    const modalRef = this.modalService.open(UploadGalleryComponent,
      {
        size: 'xl',
        scrollable: true,
        windowClass: 'modal-big-lg',
        keyboard: false,
        backdrop: 'static'
      });
    modalRef.componentInstance.uploadDetails = {};
    modalRef.result.then((result) => {
      if (result.status) {
        this.partnerForm.get(['partners', i, 'icon_url']).setValue(result.image);
        this.partnerForm.get(['partners', i, 'is_updated']).setValue(true);
      }
    });
  }

}
