<div class="container">
    <form [formGroup]="aboutForm" (ngSubmit)="onSubmit(aboutForm)">
        <div class="row">
            <div class="col-md-10 offset-md-1 mt-2" formArrayName="about"
                *ngFor="let item of about().controls; let i = index;">
                <div class="border shadow bg-white p-4 mt-2" [formGroupName]="i">
                    <a *ngIf="about().controls.length>1" href="javascript:void(0)" class="ms-2 close-btn"
                        (click)="removeabout(i)"><i class="uil-times fa-2x"></i></a>
                    <!-- <div class="my-2 row">
                        <div class="col-md-6">
                            <label class="col-form-label">Heading</label>
                            <input type="text" class="form-control" placeholder="Heading" formControlName="heading"
                                (change)="dataChanged(i)">
                        </div>
                    </div> -->
                    <div class="my-2 row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label class="col-form-label">Description</label>
                                <div formArrayName="description_datas" class="btn_grp row">
                                    <div *ngFor="let lines of item.get('description_datas').controls; let j=index"
                                        class="col-md-6">
                                        <div [formGroupName]="j" class="description">

                                            <div class="form-group my-2">
                                                <button type="button"
                                                    *ngIf="item.get('description_datas').controls.length!=1"
                                                    class="deletebtn btn btn-pseudo remove float-end"
                                                    (click)="deleteDescription(item.controls.description_datas, j);dataChanged(i);"><i
                                                        class="fa fa-times text-danger"></i></button>
                                                <ckeditor [editor]="Editor" formControlName="text"
                                                    [ngClass]="{'invalid': lines.get('text').invalid}"
                                                    (change)="dataChanged(i)" data="<p>Content of the editor.</p>">
                                                </ckeditor>


                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <button type="button" class="btn btn-sm btn-primary mt-2 float-end"
                                    (click)="addNewDescription(item.controls.description_datas)"
                                    *ngIf="item.get('description_datas').controls.length<10">
                                    <i class="fa fa-plus"></i> Add Description
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12 text-center p-5">
                <div class="row">
                    <div class="offset-md-4 col-md-4">
                        <button *ngIf="about().controls.length<1" class="btn btn-primary mt-1 me-1 col-md-12"
                            type="button" (click)="addabout()"><i class="uil-plus"></i>
                            Add</button>
                        <button *ngIf="about().controls.length>0" class="btn btn-info my-1 me-1 col-md-12"
                            type="submit"><i class="bx bx-save"></i>
                            Save
                            All</button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>