import { Component, Input } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { SharedService } from 'src/app/core/services/shared.service';
import { V3ServicesService } from 'src/app/core/services/v3-services.service';
import { UploadGalleryComponent } from 'src/app/shared/ui/upload-gallery/upload-gallery.component';

@Component({
  selector: 'app-home-carousal',
  templateUrl: './home-carousal.component.html',
  styleUrls: ['./home-carousal.component.scss']
})

export class HomeCarousalComponent {

  pageDrop = 'Login';
  country = this.sharedService.selectedCmsCountryId;
  // countryList = this.sharedService.cmsCountryList;

  carousalForm: FormGroup;
  hasChanges = false;
  @Input() page;

  isUpdating = false;
  deletedImages = [];
  isSubmitted = false;

  constructor(
    private fb: FormBuilder,
    private sharedService: SharedService,
    private api: V3ServicesService,
    private toastr: ToastrService,
    private modalService: NgbModal
  ) { }


  ngOnInit() {
    this.resetForm();
    this.getData();
    // if (this.countryList.length == 0) this.getCountriesList();
  }

  resetForm() {
    this.carousalForm = this.fb.group({
      carousal: this.fb.array([
        this.fb.group({
          'image': ['', [Validators.required]],
          'mobile_image': ['', [Validators.required]],
          'link_url': ['', [Validators.required]],
          'is_updated': [false],
          'is_new': [true]
        })])
    });
  }

  // getCountriesList() {
  //   this.sharedService.getCmsCountryDetails().subscribe(
  //     (response: any) => {
  //       this.countryList = response;
  //       this.sharedService.cmsCountryList = this.countryList;
  //     })
  // }

  selectCountry(country) {
    this.sharedService.selectedCmsCountryId = country;
    this.getData();
  }

  getData() {
    if (this.page == 'home') {
      this.api.getLogHomeCarousal(this.pageDrop).subscribe((result: any) => {
        this.setData(result);
      });
    }
    // else if (this.page == 'country') {
    //   if (this.country) {
    //     this.api.getCountryCarousal(this.country).subscribe((result: any) => {
    //       console.log(result);
    //       this.setData(result);
    //     });
    //   }
    // }
  }

  setData(result) {
    this.carousalForm = this.fb.group({
      carousal: new FormArray([]),
    });
    result.images.forEach(ao => {
      (this.carousalForm.get('carousal') as FormArray).push(
        this.fb.group({
          id: [ao.id],
          image: [ao.image, [Validators.required]],
          mobile_image: [ao.mobile_image, [Validators.required]],
          link_url: [ao.link_url, [Validators.required]],
          is_updated: [false],
          is_new: [false],
        })
      );
      this.deletedImages = [];
    });
  }

  carousal(): FormArray {
    return this.carousalForm.get("carousal") as FormArray
  }

  newcarousal(): FormGroup {
    return this.fb.group({
      image: ['', [Validators.required]],
      mobile_image: ['', [Validators.required]],
      link_url: ['', [Validators.required]],
      is_new: [true]
    })
  }

  addcarousal() {
    this.carousal().push(this.newcarousal());
  }

  removecarousal(i: number) {
    this.isSubmitted = false;
    this.carousalForm.value.carousal[i].is_delete = true;
    if (this.carousalForm.value.carousal[i].hasOwnProperty("id")) this.deletedImages.push(this.carousalForm.value.carousal[i]);
    this.carousal().removeAt(i);
  }

  onSubmit(form) {
    if (form.valid) {
      this.isUpdating = true;
      this.deletedImages.forEach(ae => {
        delete ae.is_updated;
        delete ae.is_new;
        form.value.carousal.push(ae);
      })

      if (this.page == 'home') {
        this.api.postLoginHomeCarousal({
          type: this.pageDrop,
          images: form.value.carousal,
        }).subscribe(
          () => {
            this.toastr.success("Content Updated Successfully!");
            this.getData();
            this.hasChanges = false;
            this.isUpdating = false;
          },
          () => {
            this.isUpdating = false;
          })
      }
      // else if (this.page == 'country') {
      //   this.api.postCountryCarousal({
      //     country: this.country,
      //     images: form.value.carousal,
      //   }).subscribe(
      //     () => {
      //       this.toastr.success("Content Updated Successfully!");
      //       this.getData();
      //       this.hasChanges = false;
      //       this.isUpdating = false;
      //     },
      //     () => {
      //       this.isUpdating = false;
      //     })
      // }
    } else {
      this.isSubmitted = true;
      this.toastr.warning("Fill all fields and Save");
    }
  }

  openUploadModal(i, field) {
    const modalRef = this.modalService.open(UploadGalleryComponent,
      {
        size: 'xl',
        scrollable: true,
        windowClass: 'modal-big-lg',
        keyboard: false,
        backdrop: 'static'
      });
    modalRef.componentInstance.uploadDetails = {};
    modalRef.result.then((result) => {
      if (result.status) {
        this.carousalForm.get(['carousal', i, field]).setValue(result.image);
        this.carousalForm.get(['carousal', i, 'is_updated']).setValue(!this.carousalForm.get(['carousal', i, 'is_new']).value);
      }
    });
  }

  dataChanged(i) {
    let ae = this.carousalForm.value.carousal[i];
    if (ae.is_new) {
      delete ae.is_updated;
      // this.carousalForm.value.carousal[i].is_updated = false;
    } else {
      ae.is_updated = true;
      delete ae.is_new;
    }
  }

}
