import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { Routes, RouterModule } from "@angular/router";
import { UIModule } from "../../shared/ui/ui.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbPaginationModule } from "@ng-bootstrap/ng-bootstrap";
import { NgbNavModule } from "@ng-bootstrap/ng-bootstrap";
import { NgSelectModule } from "@ng-select/ng-select";
import { CKEditorModule } from "@ckeditor/ckeditor5-angular";

import { HomePageComponent } from "./home-page/home-page.component";
import { CountryPageComponent } from "./country-page/country-page.component";
import { CategoryPageComponent } from "./category-page/category-page.component";
import { ProductPageComponent } from "./product-page/product-page.component";

import { HeaderComponent } from "./sections/header/header.component";
import { UspFeaturesComponent } from "./sections/usp-features/usp-features.component";
import { PopularDestinationComponent } from "./sections/popular-destination/popular-destination.component";
import { VisaCategoryComponent } from "./sections/visa-category/visa-category.component";
import { FooterComponent } from "./sections/footer/footer.component";

import { DragDropModule } from "@angular/cdk/drag-drop";
import { ApplicationStepsComponent } from "./sections/application-steps/application-steps.component";
import { HeaderAltComponent } from "./sections/header-alt/header-alt.component";
import { PartnersComponent } from "./sections/partners/partners.component";
import { UsefulMediaComponent } from "./sections/useful-media/useful-media.component";
import { TestimonialsComponent } from "./sections/testimonials/testimonials.component";
import { SuitableVisaComponent } from "./sections/suitable-visa/suitable-visa.component";
import { HeaderSideInfoComponent } from "./sections/header-side-info/header-side-info.component";
import { AdditionalInfoComponent } from "./sections/additional-info/additional-info.component";
import { VouchersComponent } from "./sections/vouchers/vouchers.component";
import { HeaderCategoryComponent } from "./sections/header-category/header-category.component";
import { EligibilityComponent } from "./sections/eligibility/eligibility.component";
import { RequiredDocsComponent } from "./sections/required-docs/required-docs.component";
import { SeoComponent } from "./seo-section/seo.component";
import { FaqComponent } from "./faq-page/faq.component";
import { SeoSectionComponent } from "./sections/seo-section/seo-section.component";
import { FaqSectionComponent } from "./sections/faq-section/faq-section.component";
import { SkuDataComponent } from "./sections/sku-data/sku-data.component";
import { SkuPricingComponent } from "./sections/sku-data/sku-pricing/sku-pricing.component";
import { SkuDetailsComponent } from "./sections/sku-data/sku-details/sku-details.component";
import { ConsultantPageComponent } from "./consultant-page/consultant-page.component";
import { AboutUsPageComponent } from "./about-us-page/about-us-page.component";
import { ConsultancyServiceComponent } from "./sections/consultancy-service/consultancy-service.component";
import { HeaderAboutComponent } from "./sections/header-about/header-about.component";
import { AboutAboutComponent } from "./sections/about-about/about-about.component";
import { AboutInfosComponent } from "./sections/about-infos/about-infos.component";
import { AboutStatisticsComponent } from "./sections/about-statistics/about-statistics.component";
import { TestimonialPageComponent } from "./testimonial-page/testimonial-page.component";
import { PublishBtnComponent } from "./ui-elements/publish-btn/publish-btn.component";
import { PreviewBtnComponent } from "./ui-elements/preview-btn/preview-btn.component";
import { GeneralComponent } from "./general/general.component";
import { PrivacyPolicyComponent } from "./sections/privacy-policy/privacy-policy.component";
import { TermsAndConditionsComponent } from "./sections/terms-and-conditions/terms-and-conditions.component";
import { CountryKeywordComponent } from "./sections/country-keyword/country-keyword.component";
import { FooterListComponent } from "./sections/footer/footer-list/footer-list.component";
import { AuthGuard } from "src/app/core/guards/auth.guard";
import { HomeApplicationStepsComponent } from "./sections-v2/home-application-steps/home-application-steps.component";
import { HomeHeaderComponent } from "./sections-v2/home-header/home-header.component";
import { CountryHeaderComponent } from "./sections-v2/country-header/country-header.component";
import { CountrySuitableVisaComponent } from "./sections-v2/country-suitable-visa/country-suitable-visa.component";
import { ContactUsPageComponent } from "./contact-us-page/contact-us-page.component";
import { HeaderContactUsComponent } from "./sections/header-contact-us/header-contact-us.component";
import { ContactUsSideInfoComponent } from "./sections/contact-us-side-info/contact-us-side-info.component";
import { ConsultantVisaproDescriptionComponent } from "./sections-v2/consultant-visapro-description/consultant-visapro-description.component";
import { CustomSortComponent } from "./ui-elements/custom-sort/custom-sort.component";
import { UpdateFormComponent } from "./sections/country-keyword/update-form/update-form.component";
import { BlogComponent } from "./blog/blog.component";
import { MediaHeaderComponent } from "./sections-v2/media-header/media-header.component";
import { MediaDescriptionComponent } from "./sections-v2/media-description/media-description.component";
import { AuthorPageComponent } from "./blog/author-page/author-page.component";
import { AuthorComponent } from "./sections-v2/author/author.component";
import { SpecificPageComponent } from "./blog/specific-page/specific-page.component";
import { SpecificContentComponent } from "./sections-v2/specific-content/specific-content.component";
import { NewsComponent } from "./news/news.component";
import { NewsAuthorPageComponent } from "./news/news-author-page/news-author-page.component";
import { NewsSpecificPageComponent } from "./news/news-specific-page/news-specific-page.component";
import { SkuSecondformComponent } from "./sections/sku-data/sku-secondform/sku-secondform.component";
import { SpecificContentSeoComponent } from "./sections-v2/specific-content-seo/specific-content-seo.component";
import { MediaSeoComponent } from "./sections-v2/media-seo/media-seo.component";
import { AuthorSpecificSeoComponent } from "./sections-v2/author-specific-seo/author-specific-seo.component";
import { SeoDescriptionComponent } from "./sections-v2/seo-description/seo-description.component";
import { SitemapComponent } from "./sections/sitemap/sitemap.component";
import { XmlFormatterPipe } from "src/app/core/pipe/xml-formatter";
import { UserAgreementComponent } from "./sections/user-agreement/user-agreement.component";
import { HomeCarousalComponent } from './sections-v3/home-carousal/home-carousal.component';
import { YoutubeLinksComponent } from './sections-v3/youtube-links/youtube-links.component';
import { HomeVisaKindComponent } from './sections-v3/home-visa-kind/home-visa-kind.component';
import { FooterServicesComponent } from './sections-v3/footer-services/footer-services.component';
import { OtherCategoriesComponent } from './sections-v3/other-categories/other-categories.component';
import { HomeCategoriesComponent } from './sections-v3/home-categories/home-categories.component';
import { VisaExpertComponent } from './sections-v3/visa-expert/visa-expert.component';
import { AboutVisaComponent } from './sections-v3/about-visa/about-visa.component';
import { CategoryHeaderComponent } from './sections-v3/category-header/category-header.component';
import { ProductBenefitsComponent } from './sections-v3/product-benefits/product-benefits.component';
import { HeaderMenuComponent } from './sections-v3/header-menu/header-menu.component';
import { SnackbarComponent } from './sections-v3/snackbar/snackbar.component';
import { PaymentBenefitsComponent } from './sections-v3/payment-benefits/payment-benefits.component';
import { ProductInfoComponent } from './sections-v3/product-info/product-info.component';
import { CountryWarningComponent } from './sections-v3/country-warning/country-warning.component';
import { ProductWarningComponent } from './sections-v3/product-warning/product-warning.component';
import { InteractiveBannerComponent } from "./sections-v3/interactive-banner/interactive-banner.component";

const routes: Routes = [
  {
    path: "home-page",
    component: HomePageComponent,
    canActivate: [AuthGuard],
    data: { requiredPermission: "content_management_home_view" },
  },
  {
    path: "country-page",
    component: CountryPageComponent,
    canActivate: [AuthGuard],
    data: { requiredPermission: "content_management_country_view" },
  },
  {
    path: "category-page",
    component: CategoryPageComponent,
    canActivate: [AuthGuard],
    data: { requiredPermission: "content_management_category_view" },
  },
  {
    path: "product-page",
    component: ProductPageComponent,
    canActivate: [AuthGuard],
    data: { requiredPermission: "content_management_category_view" },
  },
  {
    path: "faq",
    component: FaqComponent,
    canActivate: [AuthGuard],
    data: { requiredPermission: "content_management_product_view" },
  },
  {
    path: "seo",
    component: SeoComponent,
    canActivate: [AuthGuard],
    data: { requiredPermission: "content_management_seo_view" },
  },
  {
    path: "consultant-page",
    component: ConsultantPageComponent,
    canActivate: [AuthGuard],
    data: { requiredPermission: "content_management_consultant_view" },
  },
  {
    path: "about-us-page",
    component: AboutUsPageComponent,
    canActivate: [AuthGuard],
    data: { requiredPermission: "content_management_about_us_view" },
  },
  {
    path: "contact-us-page",
    component: ContactUsPageComponent,
    canActivate: [AuthGuard],
    data: { requiredPermission: "content_management_contact_us_view" },
  },
  {
    path: "testimonial-page",
    component: TestimonialPageComponent,
    canActivate: [AuthGuard],
    data: { requiredPermission: "content_management_testimonial_view" },
  },
  { path: "general", component: GeneralComponent },
  { path: "blog", component: BlogComponent, canActivate: [AuthGuard] },
  { path: "author", component: AuthorPageComponent, canActivate: [AuthGuard] },
  {
    path: "specific",
    component: SpecificPageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "specific/description",
    component: SeoDescriptionComponent,
    canActivate: [AuthGuard],
  },
  { path: "news", component: NewsComponent, canActivate: [AuthGuard] },
  {
    path: "news-specific",
    component: NewsSpecificPageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "news-author",
    component: NewsAuthorPageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "sem",
    loadChildren: () => import("./sem/sem.module").then((m) => m.SemModule),
  },
];

@NgModule({
  declarations: [
    HomePageComponent,
    CountryPageComponent,
    CategoryPageComponent,
    ProductPageComponent,
    HeaderComponent,
    UspFeaturesComponent,
    PopularDestinationComponent,
    VisaCategoryComponent,
    FooterComponent,
    ApplicationStepsComponent,
    HeaderAltComponent,
    PartnersComponent,
    UsefulMediaComponent,
    SuitableVisaComponent,
    HeaderSideInfoComponent,
    AdditionalInfoComponent,
    VouchersComponent,
    HeaderCategoryComponent,
    EligibilityComponent,
    RequiredDocsComponent,
    FaqComponent,
    SeoComponent,
    SeoSectionComponent,
    FaqSectionComponent,
    SkuDataComponent,
    SkuPricingComponent,
    SkuDetailsComponent,
    ConsultantPageComponent,
    AboutUsPageComponent,
    ContactUsPageComponent,
    ConsultancyServiceComponent,
    HeaderAboutComponent,
    HeaderContactUsComponent,
    AboutAboutComponent,
    AboutInfosComponent,
    AboutStatisticsComponent,
    TestimonialPageComponent,
    PublishBtnComponent,
    PreviewBtnComponent,
    GeneralComponent,
    PrivacyPolicyComponent,
    TermsAndConditionsComponent,
    CountryKeywordComponent,
    FooterListComponent,
    HomeApplicationStepsComponent,
    CountryHeaderComponent,
    CountrySuitableVisaComponent,
    ContactUsSideInfoComponent,
    ConsultantVisaproDescriptionComponent,
    UpdateFormComponent,
    BlogComponent,
    MediaHeaderComponent,
    MediaDescriptionComponent,
    AuthorComponent,
    AuthorPageComponent,
    SpecificPageComponent,
    SpecificContentComponent,
    NewsComponent,
    NewsAuthorPageComponent,
    NewsSpecificPageComponent,
    SkuSecondformComponent,
    SpecificContentSeoComponent,
    MediaSeoComponent,
    AuthorSpecificSeoComponent,
    SeoDescriptionComponent,
    SitemapComponent,
    XmlFormatterPipe,
    UserAgreementComponent,
    HomeVisaKindComponent,
    FooterServicesComponent,
    OtherCategoriesComponent,
    HomeCategoriesComponent,
    VisaExpertComponent,
    AboutVisaComponent,
    CategoryHeaderComponent,
    ProductBenefitsComponent,
    HeaderMenuComponent,
    SnackbarComponent,
    PaymentBenefitsComponent,
    ProductInfoComponent,
    CountryWarningComponent,
    ProductWarningComponent
  ],
  imports: [
    UIModule,
    FormsModule,
    NgbNavModule,
    CommonModule,
    DragDropModule,
    ReactiveFormsModule,
    NgbPaginationModule,
    NgSelectModule,
    CKEditorModule,
    RouterModule.forChild(routes),
    InteractiveBannerComponent,
    HomeHeaderComponent,
    TestimonialsComponent,
    HomeCarousalComponent,
    YoutubeLinksComponent,
    CustomSortComponent,
  ],
  exports: [RouterModule],
})
export class ContentManagementModule { }
