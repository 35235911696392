import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from './core/guards/auth.guard';
import { LayoutComponent } from './layouts/layout.component';
import { environment } from 'src/environments/environment';

const routes: Routes = [
  { path: '', redirectTo: 'products/country', pathMatch: 'full' },
  { path: 'account', loadChildren: () => import('./account/account.module').then(m => m.AccountModule) },
  { path: 'robots.txt', loadChildren: () => import('./robots/robots.module').then(m => m.RobotsModule) },
  { path: '', component: LayoutComponent, loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule), canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: environment.useHash,
    scrollPositionRestoration: 'top'
  })],
  exports: [RouterModule]
})
// useHash: true 
export class AppRoutingModule { }
