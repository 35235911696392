import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { switchMap } from "rxjs";
import { SharedService } from "src/app/core/services/shared.service";
import { VisaManageService } from "src/app/core/services/visa-manage.service";

@Component({
  selector: "app-application-details",
  templateUrl: "./application-details.component.html",
  styleUrls: ["./application-details.component.scss"],
})
export class ApplicationDetailsComponent implements OnInit {
  backUrl: string;
  application_id: string;
  applicationDetails: any;
  insuranceDetails: any;
  paymentDetails: any;
  primaryTraveller: any;
  travellersDetails: any;
  title: string;
  isLoading = false;
  paxDetails: any = {};
  customerDetailId: any;
  pageNumber: number;

  constructor(
    private route: ActivatedRoute,
    private api: VisaManageService,
    private sharedService: SharedService
  ) { }




  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.pageNumber = params["page"];
      this.title = params["title"];
      if (this.title.includes("visa-process")) {
        this.backUrl = "/visa/process";
      } else if (this.title.includes("b2b-diy")) {
        this.backUrl = "/agents/diy";
      } else if (this.title.includes("diy")) {
        this.backUrl = "/visa/diy";
      } else {
        this.backUrl = "/agents/reports";
      }
      // Do something with the id
    });
    this.application_id = this.route.snapshot.paramMap.get("id");

    // this.getApplicationDetails(this.application_id);
    this.getPaxDetails(this.application_id);
    // this.primaryTraveller = this.api.primaryTraveller;
    // this.travellersDetails = this.api.travellersDetails;
  }

  // getApplicationDetails(application_id){
  //   this.applicationDetails = this.api.applicationDetails;
  // }
  getApplicationDeatails(event) {
    if (event) {
      this.getPaxDetails(this.application_id);
    }
  }

  getProfileData(id: any) {
    return this.api.getProfileDetails(id);
  }
  getPaxDetails(application_id) {
    this.isLoading = true;
    this.api
      .paxVisaprocess(application_id)
      .pipe(
        switchMap((result: any) => {
          this.paxDetails.paxIds = result.pax_ids;
          this.paxDetails.count = result.count;

          this.paxDetails.workflow = result.workflow_assigned;
          this.isLoading = false;
          this.applicationDetails = result.application_details;
          this.paymentDetails = result.payment_details;
          this.travellersDetails = result.traveller_details;
          this.insuranceDetails = result.insurance_details;
          this.primaryTraveller = [result.primary_customer];
          this.customerDetailId =
            result?.my_profile_user?.myprofile_customer_detail_id;
          return this.getProfileData(
            result?.my_profile_user?.myprofile_customer_detail_id
          );
        })
      )
      .subscribe((res: any) => {
        this.paxDetails.myProfile = res?.response;
        const data = {
          profile: res?.response,
          myprofile_customer_detail_id: this.customerDetailId,
        };
        this.sharedService.setProfileDropDowns(data);
      });
  }
}
