import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { V3ServicesService } from 'src/app/core/services/v3-services.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UploadGalleryComponent } from 'src/app/shared/ui/upload-gallery/upload-gallery.component';

@Component({
  selector: 'app-visa-expert',
  templateUrl: './visa-expert.component.html',
  styleUrls: ['./visa-expert.component.scss']
})
export class VisaExpertComponent {

  @Input() country;
  @Input() page;
  expertForm: FormGroup;
  hasChanges: boolean;
  expertData: any;
  isUpdating: boolean;
  isSubmitted: boolean;
  isNew = false;

  constructor(private fb: FormBuilder,
    private toastr: ToastrService,
    private api: V3ServicesService, private modalService: NgbModal) { }

  ngOnInit(): void {
    this.resetForm();
    this.getData();
  }

  resetForm(data?) {

    this.expertForm = this.fb.group({
      id: [data ? data.id : null],
      name: [data ? data.name : '', [Validators.required]],
      country: [this.country],
      description: [data ? data?.description : '', [Validators.required]],
      photo: [data ? data?.photo : '', [Validators.required]],
      visa_approved: [data ? data?.visa_approved : '', [Validators.required]],
      approval_rate: [data ? data?.approval_rate : '', [Validators.required]],
      experience: [data ? data?.experience : '', [Validators.required]],
      is_enabled: [data ? data?.is_enabled : false],
    })
    this.onChanges();
  }

  onChanges(): void {
    this.expertForm.valueChanges.subscribe(() => {
      Object.keys(this.expertForm.controls).map((key) => {
        if (this.expertForm.get(key).dirty) this.hasChanges = true;
      })
    });
  }

  getData() {
    this.api.getVisaExpert(this.country).subscribe(
      (result: any) => {
        this.isNew = result.length == 0;
        this.expertData = result[0] || null;
        this.resetForm(this.expertData);
      })
  }

  openUploadModal(field) {
    const modalRef = this.modalService.open(UploadGalleryComponent,
      {
        size: 'xl',
        scrollable: true,
        windowClass: 'modal-big-lg',
        keyboard: false,
        backdrop: 'static'
      });
    modalRef.componentInstance.uploadDetails = {};
    modalRef.result.then((result) => {
      if (result.status) {
        this.expertForm.get(field).setValue(result.image);
      }
    });
  }

  onSubmit(form) {
    if (form.valid) {
      this.isUpdating = true;

      if (this.isNew) {
        this.api.setVisaExpert(form.value).subscribe(
          (result: any) => {
            this.resetForm(result);
            this.toastr.success("Content Added Successfully!");
            this.hasChanges = false;
            this.isUpdating = false;
            this.isNew = false;
          },
          () => {
            this.isUpdating = false;
          }
        )
      }
      else {
        this.api.updateVisaExpert(form.value.id, form.value).subscribe(
          (result: any) => {
            this.resetForm(result);
            this.toastr.success("Content Updated Successfully!");
            this.hasChanges = false;
            this.isUpdating = false;
          },
          () => {
            this.isUpdating = false;
          }
        )
      }
    }
  }

}
