import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { V3ServicesService } from 'src/app/core/services/v3-services.service';
import { UploadGalleryComponent } from 'src/app/shared/ui/upload-gallery/upload-gallery.component';

@Component({
  selector: 'app-category-header',
  templateUrl: './category-header.component.html',
  styleUrls: ['./category-header.component.scss']
})
export class CategoryHeaderComponent {
  @Input() page;
  @Input() country;
  @Input() category;

  headerForm: FormGroup;
  hasChanges = false;
  isUpdating = false;
  isSubmitted = false;
  isNew = false;

  constructor(private fb: FormBuilder, private api: V3ServicesService,
    private toastr: ToastrService, private modalService: NgbModal,) { }

  ngOnInit(): void {
    this.resetForm();
    this.getData();
  }


  resetForm(data?) {
    this.headerForm = this.fb.group({
      id: [data ? data.id : null],
      category: [data ? data.category : this.category],
      heading: [data ? data.heading : '', [Validators.required]],
      sub_heading: [data ? data.sub_heading : '', [Validators.required]],
      header_image_url: [data ? data.header_image_url : '', [Validators.required]],
      mob_header_image_url: [data ? data.mob_header_image_url : '-', [Validators.required]],
    })
    this.onChanges();
  }

  onChanges(): void {
    this.headerForm.valueChanges.subscribe(() => {
      Object.keys(this.headerForm.controls).map((key) => {
        if (this.headerForm.get(key).dirty) this.hasChanges = true;
      })
    });
  }


  onSubmit(form) {
    if (form.valid) {
      this.isUpdating = true;
      if (this.isNew) {
        this.api.postCategoryHeader(form.value).subscribe(
          (result: any) => {
            this.resetForm(result);
            this.toastr.success("Content Updated Successfully!");
            this.hasChanges = false;
            this.isUpdating = false;
            this.isNew = false;
          },
          () => {
            this.isUpdating = false;
          }
        )
      } else {
        this.api.updateCategoryHeader(form.value.id, form.value).subscribe(
          (result: any) => {
            this.resetForm(result);
            this.toastr.success("Content Updated Successfully!");
            this.hasChanges = false;
            this.isUpdating = false;
          },
          () => {
            this.isUpdating = false;
          }
        )
      }
    } else {
      this.isSubmitted = true;
      this.toastr.warning("Fill all fields and Save");
    }
  }

  getData() {
    this.api.getCategoryHeader(this.category).subscribe(
      (result: any) => {
        this.resetForm(result[0]);
        if (result.length == 0) this.isNew = true;
        else this.isNew = false;
      })
  }

  openUploadModal(field) {
    const modalRef = this.modalService.open(UploadGalleryComponent, {
      size: "xl",
      scrollable: true,
      windowClass: "modal-big-lg",
      keyboard: false,
      backdrop: "static",
    });
    modalRef.componentInstance.uploadDetails = {};
    modalRef.result.then((result) => {
      if (result.status) {
        this.headerForm.get(field).setValue(result?.image);
      }
    });
  }
}
