<div class="container">
    <form class="row" [formGroup]="headerForm" (ngSubmit)="onSubmit(headerForm)">
        <div class="col-md-8 offset-md-2 p-4 mt-2">
            <div class="border shadow p-3">
                <div class="my-2 row">
                    <label class="col-md-4 col-form-label">Banner Image URL</label>
                    <div class="col-md-8 d-flex">
                        <input type="text" class="form-control" placeholder="URL" formControlName="interactive_banner">
                        <a [href]="headerForm.value.interactive_banner" target="_blank"><img class="ms-1" height="40"
                                [src]="headerForm.value.interactive_banner"></a>
                        <button class="btn btn-primary ms-1" type="button"
                            (click)="openUploadModal('interactive_banner')"><i
                                class="fas fa-cloud-upload-alt"></i></button>
                    </div>
                </div>
                <div class="my-2 row">
                    <label class="col-md-4 col-form-label">Banner Image URL for Mobile</label>
                    <div class="col-md-8 d-flex">
                        <input type="text" class="form-control" placeholder="URL"
                            formControlName="interactive_banner_mob">
                        <a [href]="headerForm.value.interactive_banner_mob" target="_blank"><img class="ms-1"
                                height="40" [src]="headerForm.value.interactive_banner_mob"></a>
                        <button class="btn btn-primary ms-1" type="button"
                            (click)="openUploadModal('interactive_banner_mob')"><i
                                class="fas fa-cloud-upload-alt"></i></button>
                    </div>
                </div>
                <div class="my-2 row">
                    <label class="col-md-4 col-form-label">Banner Link</label>
                    <div class="col-md-8 d-flex">
                        <input type="url" class="form-control" placeholder="URL"
                            formControlName="interactive_banner_link">
                    </div>
                </div>
                <div class="my-2 row">
                    <div class="col-md-12">
                        <button class="btn btn-info float-end me-5"><i
                                [ngClass]="{'fa fa-pulse fa-spin fa-spinner': isUpdating,'bx bx-save': !isUpdating}"></i>
                            Save
                            Changes</button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>