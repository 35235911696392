import { Component, OnInit, ViewChild } from "@angular/core";
import {
  dataToSend,
  filteredData,
  searchItems,
} from "src/app/core/models/visa-management.model";
import { FilterModalComponent } from "../modal/filter-modal/filter-modal.component";
import { debounceTime } from "rxjs";
import { CsvDownloadService } from "src/app/core/services/csv-download.service";
import { StorageService } from "src/app/core/services/storage.service";
import {
  NgbActiveModal,
  NgbModal
} from "@ng-bootstrap/ng-bootstrap";
import { VisaManageService } from "src/app/core/services/visa-manage.service";
import { AccessManagementService } from "src/app/core/services/access-management.service";
import { ToastrService } from "ngx-toastr";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-diy",
  templateUrl: "./diy.component.html",
  styleUrls: ["./diy.component.scss"],
})
export class DiyComponent implements OnInit {
  breadCrumbItems: Array<{}>;
  pageSize = 10;
  pageNumber: number = 1;
  filteredCheck: boolean = false;
  filterQueryParams: any;
  isHide: boolean = false;
  filterMessage: string;
  dropDownItem: string = "";
  isAscend: boolean = true;
  sortDate: string = "last";
  isExpertLoading = [];
  userList: any;
  expertListValue = [];
  searchFields: searchItems = {
    name: "",
    country: "",
    status: "",
    search: "",
    dropDownItem: "",
    choice: "diy",
    sort: this.sortDate,
  };
  page_details: any = {
    count: 0,
    next: "",
    previous: "",
  };
  searchText: string = "";
  search = {
    name: "",
    country: "",
    category: "",
    app_date: "",
  };

  isLoading = false;
  processData: any = [];
  isPaid: boolean = true;
  private readonly debounceTimeMs = 300;
  @ViewChild("pagination") pagination: any;
  constructor(
    public activeModal: NgbActiveModal,
    private api: VisaManageService,
    private modalService: NgbModal,
    private storageService: StorageService,
    private csvDownloadService: CsvDownloadService,
    private accessManagementService: AccessManagementService,
    private toastr: ToastrService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.breadCrumbItems = [
      { label: "Visa Management" },
      { label: "DIY", active: true },
    ];
    this.getParams();

    this.subscribeInlineEdit();
    this.getAllUsers();
  }

  getAllUsers() {
    this.accessManagementService
      .getUserListForVisaPro()
      .subscribe((res: any) => {
        this.userList = res;
      });
  }

  getParams() {
    this.route.queryParams.subscribe((params) => {
      const page = +params["page"];
      if (page) {
        this.pageNumber = page;
        let offset = this.pageSize * (page - 1);
        this.getProcessData(this.pageSize, offset);
      } else {
        this.getProcessData(this.pageSize, 0);
      }
    });
  }

  changeExpert(id, ind) {
    this.isExpertLoading[ind] = true;
    const type = "expert_edit";
    const data = {
      value: this.expertListValue[ind],
      tableData: {
        id: id,
      },
    };
    this.api.editFields(data, type).subscribe(
      (res) => {
        this.toastr.success("Expert updated successfully", "Success");
        this.isExpertLoading[ind] = false;
      },
      (err) => {
        this.isExpertLoading[ind] = false;
      }
    );
  }

  subscribeInlineEdit() {
    this.api.searchSubject
      .pipe(debounceTime(this.debounceTimeMs))
      .subscribe((searchValue) => {
        this.performEdit(searchValue);
      });
  }

  getFilter(page) {

    const filterParams = {
      ...this.filterQueryParams,
      limit: this.pageSize,
      offset: this.pageSize * (page - 1),
    }
    this.api
      .getVisaFilterResult(filterParams)
      .subscribe((response) => {
        this.processData = response.results;
      });
  }

  sortList() {
    this.isAscend = !this.isAscend;
    if (this.isAscend) {
      this.sortDate = "last";
    } else {
      this.sortDate = "first";
    }
    if (this.filterQueryParams) {
      this.filterQueryParams.sort = this.sortDate;
      this.getFilter(1);
    } else {
      this.getProcessData(this.pageSize, 0);
    }
  }

  nextPage(page) {
    if (this.filterQueryParams) {
      this.getFilter(page);
    } else {
      this.getProcessData(this.pageSize, this.pageSize * (page - 1));
    }
  }

  paidData() {
    this.isPaid = true;
    this.getProcessData(this.pageSize, 0);
  }

  unPaidData() {
    this.isPaid = false;
    this.getProcessData(this.pageSize, 0);
  }

  changeVisaStatus(id: number, value: any) {
    const type = "Visa_Status";
    const data = {
      value: value.target.value,
      tableData: {
        id: id,
      },
    };
    this.api.editFields(data, type).subscribe((res) => { });
  }
  getProcessData(limit, offset) {
    if (this.filteredCheck) {
      this.getFilter(1)
      return;
    }
    let status;

    if (this.isPaid) {
      status = "PAID";
    } else {
      status = "UNPAID";
    }

    this.searchFields.country = "";
    this.searchFields.name = "";
    this.searchFields.dropDownItem = this.dropDownItem ? this.dropDownItem : "";
    this.searchFields.status = status;
    this.searchFields.search = this.searchText ? this.searchText : "";
    this.searchFields.sort = this.sortDate;
    this.api
      .customerVisaProcessDetails(limit, offset, this.searchFields)
      .subscribe((result: any) => {

        this.processData = result.results;
        this.processData.forEach((res, index) => {
          this.isExpertLoading[index] = false;
          this.expertListValue[index] = res?.experts_id;
        });
        this.setExapndedorNot();

        this.page_details = {
          count: result.count,
          next: result.next,
          previous: result.previous,
        };
      });
  }

  onChangeData(value: any) {
    this.api.searchSubject.next(value);
  }
  performEdit(value) {
    const type = "Inline";
    this.api.editFields(value, type).subscribe((res) => { });
  }
  searchVisaData() {
    this.filteredCheck = false;
    this.getProcessData(this.pageSize, 0);
  }

  resetData(event: any, type: string) {
    this.isHide = false;
    this.filteredCheck = false;
    if (type === "search" && !event.target.value) {
      this.getProcessData(this.pageSize, 0);
    }
    if (type === "reset") {
      this.pageNumber = 1;
      this.dropDownItem = "";
      this.filterQueryParams = undefined;
      this.searchText = "";
      this.sortDate = "last";
      this.getProcessData(this.pageSize, 0);
    }
  }

  downloadCsv() {
    const filteredData = this.storageService.getItem("filterData")
      ? this.storageService.getItem("filterData")
      : "";
    const data = {
      searchText: this.searchText,
      serachKey: this.dropDownItem,
      filteredOrNot: this.filteredCheck,
      filteredData: filteredData ? filteredData : "",
      status: this.isPaid ? "PAID" : "UNPAID",
      choice: "diy",
    };
    this.api.downloadCSVFile(data, '/customer-visaprocess-details-csv/').subscribe((result) => {
      const filename = "visa-process.csv";
      this.csvDownloadService.downloadCsv(result, filename);
    });
  }

  downloadCordeliaCsv() {
    this.api.downloadCordeliaCsv().subscribe((response) => {
      const filename = "cordelia.csv";
      this.csvDownloadService.downloadCsv(response, filename);
    });
  }

  openFilterModal() {
    this.isHide = true;
    const modalRef = this.modalService.open(FilterModalComponent, {
      size: "l",
      centered: true,
      backdrop: "static",
    });
    modalRef.componentInstance.userList = this.userList;
    modalRef.componentInstance.pageSize = this.pageSize
    modalRef.result.then((result: filteredData) => {
      if (result) {
        this.processData = [];
        this.processData = result?.response?.results;
        this.filterMessage = result?.filterMessage;
        this.filterQueryParams = result.queryParmas;
        this.page_details = {
          count: result.response.count,
          next: result.response.next,
          previous: result.response.previous,
        };
        this.filteredCheck = true;
      } else {
        this.filteredCheck = false;
        this.isHide = false;
      }
    });
  }

  setExapndedorNot() {
    this.processData.forEach((result) => (result.isExpanded = false));
  }
}
