<div class="container-fluid">

    <div class="my-3 row">
        <div class="offset-md-8 col-md-4">
            <ng-select [items]="['Login', 'Home']" [multiple]="false" [placeholder]="'Select Country'" bindLabel="name"
                bindValue="id" [(ngModel)]="pageDrop" [clearable]="false" (change)="getData()"></ng-select>
        </div>
    </div>

    <div class="container">
        <form [formGroup]="carousalForm" (ngSubmit)="onSubmit(carousalForm)">
            <div class="row" *ngIf="this.page">
                <div class="col-md-4 mt-2" formArrayName="carousal"
                    *ngFor="let item of carousal().controls; let i = index;">
                    <div class="border shadow bg-white p-4 mt-2" [formGroupName]="i">
                        <a *ngIf="carousal().controls.length>1" href="javascript:void(0)" class="ms-2 close-btn"
                            (click)="removecarousal(i)"><i class="uil-times fa-2x"></i></a>
                        <div class="my-2 row">
                            <div class="col-md-12">
                                <label class="col-form-label">Image</label>
                                <div class=" d-flex">
                                    <input type="text" class="form-control" placeholder="Image Url"
                                        formControlName="image">
                                    <a [href]="carousalForm.value.carousal[i].image" target="_blank">
                                        <img class="ms-1" height="40" [src]="carousalForm.value.carousal[i].image">
                                    </a>
                                    <button class="btn btn-primary ms-1" type="button"
                                        (click)="openUploadModal(i, 'image')"><i
                                            class="fas fa-cloud-upload-alt"></i></button>
                                </div>
                            </div>
                        </div>
                        <div class="my-2 row">
                            <div class="col-md-12">
                                <label class="col-form-label">Mobile Image</label>
                                <div class=" d-flex">
                                    <input type="text" class="form-control" placeholder="Image Url"
                                        formControlName="mobile_image">
                                    <a [href]="carousalForm.value.carousal[i].mobile_image" target="_blank">
                                        <img class="ms-1" height="40"
                                            [src]="carousalForm.value.carousal[i].mobile_image">
                                    </a>
                                    <button class="btn btn-primary ms-1" type="button"
                                        (click)="openUploadModal(i, 'mobile_image')"><i
                                            class="fas fa-cloud-upload-alt"></i></button>
                                </div>
                            </div>
                        </div>
                        <div class="my-2 row">
                            <div class="col-md-12">
                                <label class="col-form-label">Redirection URL</label>
                                <div class="d-flex">
                                    <input type="text" (change)="dataChanged(i)" class="form-control"
                                        placeholder="Redirection Url" formControlName="link_url">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 p-5">
                    <div class="row">
                        <button class="btn btn-primary mt-1 me-1 col-md-12" type="button" (click)="addcarousal()"><i
                                class="uil-plus"></i> Add</button>
                        <button class="btn btn-info mt-1 me-1 col-md-12" type="submit"><i
                                [ngClass]="{'fa fa-pulse fa-spin fa-spinner': isUpdating,'bx bx-save': !isUpdating}"></i>
                            Save All</button>
                        <!-- <app-publish-btn class="col-md-12 p-0 mt-1" [publish]="'AboutusInfo'"
                            [page]="page"></app-publish-btn> -->
                    </div>
                </div>
            </div>
        </form>
    </div>

</div>