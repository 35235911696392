<div class="container-fluid">
  <!-- start page title -->
  <app-page-title title="Product Page" [breadcrumbItems]="breadCrumbItems"></app-page-title>

  <div class="my-3 row">
    <div class="col-md-4">
      <ng-select [(items)]="countryList" [multiple]="false" [placeholder]="'Select Country'" bindLabel="name"
        bindValue="id" [(ngModel)]="country" [clearable]="false" (change)="selectCountry(country)"></ng-select>
    </div>
    <div class="col-md-4">
      <ng-select [(items)]="categoryList" [multiple]="false" [placeholder]="'Select Category'" bindLabel="name"
        bindValue="id" [(ngModel)]="category" [clearable]="false" (change)="selectCategory(category)"></ng-select>
    </div>
    <div class="col-md-4">
      <ng-select [(items)]="productList" [multiple]="false" [placeholder]="'Select Product'" bindLabel="name"
        bindValue="id" [(ngModel)]="product" [clearable]="false" (change)="selectProduct(product)"></ng-select>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-6 offset-lg-3 set-minwh-1" *ngIf="country==null || category==null || product==null">
      <div class="card text-center p-4">
        Select Country to Continue
      </div>
    </div>
    <div class="col-12" *ngIf="country && category && product">
      <div class="card">
        <div class="card-body">
          <app-preview-btn [page]="page"></app-preview-btn>
          <ul ngbNav #justifiednavpills="ngbNav" [activeId]="14" class="nav-pills nav-justified bg-light">
            <li [ngbNavItem]="14">
              <a ngbNavLink>
                <span>SKU&nbsp;Data</span>
              </a>
              <ng-template ngbNavContent>
                <app-sku-data [country]="country" [page]="page" [product]="product"></app-sku-data>
              </ng-template>
            </li>
            <li [ngbNavItem]="5">
              <a ngbNavLink>
                <span>Application&nbsp;Steps</span>
              </a>
              <ng-template ngbNavContent>
                <app-application-steps [country]="country" [page]="page" [product]="product"></app-application-steps>
              </ng-template>
            </li>
            <li [ngbNavItem]="6">
              <a ngbNavLink>
                <span>Required&nbsp;Documents</span>
              </a>
              <ng-template ngbNavContent>
                <app-required-docs [country]="country" [page]="page" [product]="product"></app-required-docs>
              </ng-template>
            </li>
            <li [ngbNavItem]="7">
              <a ngbNavLink>
                <span>Additional&nbsp;Info</span>
              </a>
              <ng-template ngbNavContent>
                <app-additional-info [country]="country" [page]="page" [product]="product"></app-additional-info>
              </ng-template>
            </li>
            <li [ngbNavItem]="9">
              <a ngbNavLink>
                <span>Vouchers</span>
              </a>
              <ng-template ngbNavContent>
                <app-vouchers [country]="country" [page]="page" [product]="product"></app-vouchers>
              </ng-template>
            </li>
            <li [ngbNavItem]="10">
              <a ngbNavLink>
                <span>Benefits</span>
              </a>
              <ng-template ngbNavContent>
                <app-product-benefits [country]="country" [page]="page" [product]="product"></app-product-benefits>
              </ng-template>
            </li>
          </ul>
          <div [ngbNavOutlet]="justifiednavpills"></div>
        </div>
      </div>
    </div>
  </div>
</div>