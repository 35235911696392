import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SharedService } from 'src/app/core/services/shared.service';
import { V3ServicesService } from 'src/app/core/services/v3-services.service';
import { ToastrService } from 'ngx-toastr';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';


@Component({
  selector: 'app-home-categories',
  templateUrl: './home-categories.component.html',
  styleUrls: ['./home-categories.component.scss']
})
export class HomeCategoriesComponent {
  categoriesList: any;
  constructor(private sharedService: SharedService,
    private api: V3ServicesService,
    private modalService: NgbModal,
    private toastr: ToastrService) { }

  ngOnInit() {
    this.getCategories();
  }

  getCategories() {
    this.api.getHomeCategories().subscribe((result: any) => {
      this.categoriesList = result;
    });
  }

  changeStatus(item) {
    this.api.updateCategoryStatus(item.id, item).subscribe(() => {
      this.toastr.success(`Status Changed Successfully!`);
    });
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.categoriesList, event.previousIndex, event.currentIndex);
    let pos = [];
    this.categoriesList.forEach((ae: any, i) => {
      pos.push({
        id: ae.id,
        position: i + 1
      })
    });
    this.api.changeCategoryPosition({
      categories: pos
    }).subscribe(
      () => {
        this.getCategories();
        this.toastr.success(`Position Changed Successfully!`);
      },
      error => {
        console.log(error);
        this.toastr.error(`Error!`, `Oops! API Failed!`);
      })
  }
}
