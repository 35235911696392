import { Component, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { V3ServicesService } from 'src/app/core/services/v3-services.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UploadGalleryComponent } from 'src/app/shared/ui/upload-gallery/upload-gallery.component';
import * as ClassicEditor from "@ckeditor/ckeditor5-build-classic";

@Component({
  selector: 'app-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss']
})
export class SnackbarComponent {
  @Input() page;
  @Input() country;
  @Input() category;
  public Editor = ClassicEditor;
  snackForm: FormGroup;
  hasChanges: boolean;
  snackData: any;
  isUpdating: boolean;
  isSubmitted: boolean;
  isNew = false;
  public editorConfig = {
    toolbar: {
      items: ['bold', 'italic']
    }
  }

  constructor(private fb: FormBuilder,
    private toastr: ToastrService,
    private api: V3ServicesService, private modalService: NgbModal) { }

  ngOnInit(): void {
    this.resetForm();
    this.getData();
  }

  resetForm(data?) {

    this.snackForm = this.fb.group({
      id: [data ? data.id : null],
      country: [this.country],
      category: [this.category],
      text: [data ? data.text : '', [Validators.required]],
      icon: [data ? data?.icon : '', [Validators.required]],
      url: ['-', [Validators.required]],
      is_enabled: [data ? data?.is_enabled : false],
    })
    this.onChanges();
  }

  onChanges(): void {
    this.snackForm.valueChanges.subscribe(() => {
      Object.keys(this.snackForm.controls).map((key) => {
        if (this.snackForm.get(key).dirty) this.hasChanges = true;
      })
    });
  }

  getData() {
    if (this.page == 'category') {
      this.api.getCategorySnackbar(this.category).subscribe(
        (result: any) => {
          this.isNew = result.length == 0;
          this.snackData = result[0] || null;
          this.resetForm(this.snackData);
        })
    }
    else if (this.page == 'country') {
      this.api.getCountrySnackbar(this.country).subscribe(
        (result: any) => {
          this.isNew = result.length == 0;
          this.snackData = result[0] || null;
          this.resetForm(this.snackData);
        })
    }
  }

  openUploadModal(field) {
    const modalRef = this.modalService.open(UploadGalleryComponent,
      {
        size: 'xl',
        scrollable: true,
        windowClass: 'modal-big-lg',
        keyboard: false,
        backdrop: 'static'
      });
    modalRef.componentInstance.uploadDetails = {};
    modalRef.result.then((result) => {
      if (result.status) {
        this.snackForm.get(field).setValue(result.image);
      }
    });
  }

  onSubmit(form) {
    if (form.valid) {
      form.value.text = this.cleanHTMLContent(form.value.text);
      this.isUpdating = true;
      if (this.page == 'category') {
        if (this.isNew) {
          this.api.setCategorySnackbar(form.value).subscribe(
            (result: any) => {
              this.resetForm(result);
              this.toastr.success("Content Added Successfully!");
              this.hasChanges = false;
              this.isUpdating = false;
              this.isNew = false;
            },
            () => {
              this.isUpdating = false;
            }
          )
        }
        else {
          this.api.updateCategorySnackbar(form.value.id, form.value).subscribe(
            (result: any) => {
              this.resetForm(result);
              this.toastr.success("Content Updated Successfully!");
              this.hasChanges = false;
              this.isUpdating = false;
            },
            () => {
              this.isUpdating = false;
            }
          )
        }
      } else if (this.page == 'country') {
        if (this.isNew) {
          this.api.setCountrySnackbar(form.value).subscribe(
            (result: any) => {
              this.resetForm(result);
              this.toastr.success("Content Added Successfully!");
              this.hasChanges = false;
              this.isUpdating = false;
              this.isNew = false;
            },
            () => {
              this.isUpdating = false;
            }
          )
        }
        else {
          this.api.updateCountrySnackbar(form.value.id, form.value).subscribe(
            (result: any) => {
              this.resetForm(result);
              this.toastr.success("Content Updated Successfully!");
              this.hasChanges = false;
              this.isUpdating = false;
            },
            () => {
              this.isUpdating = false;
            }
          )
        }
      }
    }
  }

  cleanHTMLContent(content: string): string {
    // Trim whitespace at the start and end
    content = content.trim();

    // Remove the first <p> tag if present
    if (content.startsWith('<p>')) {
      content = content.replace(/^<p[^>]*>/, '');
    }

    // Remove the last </p> tag if present
    if (content.endsWith('</p>')) {
      content = content.replace(/<\/p>$/, '');
    }

    return content;
  }
}
