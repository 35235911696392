import { Component, Input } from "@angular/core";
import { FormGroup, FormArray, FormBuilder, Validators, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HomeManagementService } from "src/app/core/services/home-management.service";
import { ToastrService } from "ngx-toastr";
import { PermissionService } from "src/app/core/services/permision.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { UploadGalleryComponent } from "src/app/shared/ui/upload-gallery/upload-gallery.component";
import { CommonModule } from "@angular/common";

@Component({
  selector: "app-home-header",
  standalone: true,
  templateUrl: "./home-header.component.html",
  styleUrls: ["./home-header.component.scss"],
  imports: [CommonModule, FormsModule, ReactiveFormsModule]
})
export class HomeHeaderComponent {
  headerForm: FormGroup;
  hasChanges = false;
  fileToUpload: File | [null] = [null];
  isUpdating = false;
  deletedheaders = [];
  isSubmitted = false;
  bannerId: any;
  mobBannerId: any;
  @Input() page;

  constructor(
    private fb: FormBuilder,
    private api: HomeManagementService,
    public permission: PermissionService,
    private toastr: ToastrService,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    this.resetForm();
    this.getheaders();
  }

  resetForm() {
    this.headerForm = this.fb.group({
      headers: this.fb.array([
        this.fb.group({
          id: [""],

          heading: ["", [Validators.required]],
          sub_heading: ["", [Validators.required]],

          banner_image_url: ["", [Validators.required]],
          mob_banner_image_url: ["", [Validators.required]],

          home_banner_image_url: ["", [Validators.required]],
          home_mob_banner_image_url: ["", [Validators.required]],

          home_banner_button_url: ["", [Validators.required]],
          count_on_us_url: ["", [Validators.required]],
          faq_url: ["", [Validators.required]],

          testimonial_rating: ["", [Validators.required]],

          is_updated: [false],
        }),
      ]),
    });
  }

  getheaders() {

    this.api.getHomeDescription(this.page == 'b2b-home' ? { b2b: true, type: 'Home' } : null).subscribe((result: any) => {
      this.bannerId = result[0]?.banner_image_url_id;
      this.mobBannerId = result[0]?.mob_banner_image_url_id;

      if (result.length == 0) {
        this.resetForm();
        return;
      }
      this.headerForm = this.fb.group({
        headers: new FormArray([]),
      });
      result.forEach((ao) => {
        (this.headerForm.get("headers") as FormArray).push(
          this.fb.group({
            id: [ao.id],

            heading: [ao.heading, [Validators.required]],
            sub_heading: [ao.sub_heading, [Validators.required]],

            banner_image_url: [ao.banner_image_url, [Validators.required]],
            mob_banner_image_url: [ao.mob_banner_image_url, [Validators.required]],

            home_banner_image_url: [ao.home_banner_image_url, [Validators.required]],
            home_mob_banner_image_url: [ao.home_mob_banner_image_url, [Validators.required]],

            home_banner_button_url: [ao.home_banner_button_url, [Validators.required]],
            count_on_us_url: [ao.count_on_us_url, [Validators.required]],
            faq_url: [ao.faq_url, [Validators.required]],

            testimonial_rating: [ao.testimonial_rating, [Validators.required]],
            is_updated: [false],
          })
        );
        this.deletedheaders = [];
      });
    });
  }

  headers(): FormArray {
    return this.headerForm.get("headers") as FormArray;
  }

  newheaders(): FormGroup {
    return this.fb.group({
      banner_image_url: ["", [Validators.required]],
      mob_banner_image_url: ["", [Validators.required]],

      home_banner_image_url: ["", [Validators.required]],
      home_mob_banner_image_url: ["", [Validators.required]],

      home_banner_button_url: ["", [Validators.required]],
      count_on_us_url: ["", [Validators.required]],
      faq_url: ["", [Validators.required]],

      testimonial_rating: ["", [Validators.required]],
      is_updated: [false],
    });
  }

  addheaders() {
    this.headers().push(this.newheaders());
  }

  removeheaders(i: number) {
    this.isSubmitted = false;
    this.headerForm.value.headers[i].is_deleted = true;
    if (this.headerForm.value.headers[i].hasOwnProperty("id"))
      this.deletedheaders.push(this.headerForm.value.headers[i]);
    this.headers().removeAt(i);
  }

  onSubmit(form) {
    if (form.valid) {
      this.isUpdating = true;
      this.deletedheaders.forEach((ae) => {
        ae.is_updated = false;
        form.value.headers.push(ae);
      });
      let dataToSend = [...form.value.headers];
      if (!dataToSend[0].id) {
        dataToSend[0].is_updated = false;
        delete dataToSend[0].id;
      }
      dataToSend[0].banner_image_url_id = this.bannerId
        ? this.bannerId
        : undefined;
      dataToSend[0].mob_banner_image_url_id = this.mobBannerId
        ? this.mobBannerId
        : undefined;
      dataToSend[0].b2b = this.page == 'b2b-home';
      this.api.postHomeDescription(dataToSend).subscribe(
        () => {
          this.toastr.success("Content Updated Successfully!");
          this.getheaders();
          this.hasChanges = false;
          this.isUpdating = false;
        },
        () => {
          this.isUpdating = false;
        }
      );
    } else {
      this.isSubmitted = true;
      this.toastr.warning("Fill all fields and Save!");
    }
  }

  dataChanged(i) {
    this.headerForm.value.headers[i].is_updated = true;
  }

  checkPermission(type) {
    return this.permission.hasPermission(type);
  }

  openUploadModal(i, field) {
    const modalRef = this.modalService.open(UploadGalleryComponent, {
      size: "xl",
      scrollable: true,
      windowClass: "modal-big-lg",
      keyboard: false,
      backdrop: "static",
    });
    modalRef.componentInstance.uploadDetails = {};
    modalRef.result.then((result) => {
      if (result.status) {
        if (field === "banner_image_url") {
          this.bannerId = result?.id;
        } else {
          this.mobBannerId = result?.id;
        }
        this.headerForm.get(["headers", i, field]).setValue(result?.image);
        this.headerForm.get(["headers", i, "is_updated"]).setValue(true);
      }
    });
  }
}
