import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { V3ServicesService } from 'src/app/core/services/v3-services.service';

@Component({
  selector: 'app-youtube-links',
  templateUrl: './youtube-links.component.html',
  styleUrls: ['./youtube-links.component.scss']
})
export class YoutubeLinksComponent {

  ytLinks: any[];
  ytLinksCopy: any[];
  isUpdating: boolean;
  deleteLink: any;

  constructor(
    private api: V3ServicesService,
    private toastr: ToastrService,
    private modalService: NgbModal
  ) { }

  ngOnInit() {
    this.getData();
  }

  getData() {
    this.ytLinks = [];
    this.ytLinksCopy = [];
    this.api.getYtLinks().subscribe(
      (response: any) => {
        this.ytLinks = response?.reverse();
        this.ytLinksCopy = this.ytLinks.map(x => Object.assign({}, x));
      });
  }

  updateYtlink(i) {
    let data = this.ytLinks[i];
    if (data.description && data.link && data.link_id) {
      if (data.id) {
        this.api.updateYtLink(data.id, data).subscribe(
          () => {
            this.toastr.success("Content Updated Successfully!");
            this.getData();
            this.isUpdating = false;
          },
          () => {
            this.isUpdating = false;
            this.toastr.error("Try Again!");
          })
      } else {
        this.api.postYtLink(data).subscribe(
          () => {
            this.toastr.success("Content Added Successfully!");
            this.getData();
            this.isUpdating = false;
          },
          () => {
            this.isUpdating = false;
            this.toastr.error("Try Again!");
          })
      }
    } else {
      this.toastr.warning("Fill all fields!");
    }
  }

  addYtlink() {
    this.ytLinks.unshift({
      'name': '-',
      'description': '',
      'link': '',
      'link_id': '',
    });
  }

  removeytlink(i) {
    this.api.deleteYtLink(this.ytLinks[i].id).subscribe(
      () => {
        this.toastr.success("Content Deleted Successfully!");
        this.getData();
        this.isUpdating = false;
      },
      () => {
        this.isUpdating = false;
        this.toastr.error("Try Again!");
      })
  }

  haveSameValues(i) {
    if (this.ytLinks[i] && this.ytLinksCopy[i]) {
      const obj1 = this.ytLinks[i];
      const obj2 = this.ytLinksCopy[i];
      const keys1 = Object.keys(obj1);
      const keys2 = Object.keys(obj2);
      if (keys1.length !== keys2.length) return false;
      return keys1.every(key => obj2.hasOwnProperty(key) && obj1[key] === obj2[key]);
    } return false;
  }

  openDeleteModal(i, template) {
    this.deleteLink = this.ytLinks[i];
    const modalRef = this.modalService.open(template,
      {
        size: 'xl',
        scrollable: true,
        windowClass: 'modal-big-lg',
        keyboard: false,
        backdrop: 'static'
      });
    modalRef.result.then((result) => {
      if (result == 'ok') {
        this.removeytlink(i);
      }
    });
  }

}
