<div class="w-100 text-end mt-3" *ngIf="testimonialForm.value.testimonial.length>0">
    <a class="cpr btn btn-primary" (click)="showSortModal(testimonialForm.value.testimonial, 'author')">
        <i class="fa fa-sort" aria-hidden="true"></i> Change Position</a>
</div>
<div class="container">
    <div class="my-3 row" *ngIf="page != 'b2b-home'">
        <div class="offset-md-8 col-md-4">
            <ng-select [(items)]="countryList" [multiple]="false" [placeholder]="'Select Country'" bindLabel="name"
                bindValue="id" [(ngModel)]="country" [clearable]="false" (change)="selectCountry(country)"></ng-select>
        </div>
    </div>
    <div class="row" *ngIf="country || page == 'b2b-home'">
        <div class="col-md-12">
            <form [formGroup]="testimonialForm" (ngSubmit)="onSubmit(testimonialForm)">
                <div class="row">
                    <div class="col-md-6 mt-2" formArrayName="testimonial"
                        *ngFor="let item of testimonial().controls; let i = index;">
                        <div class="border shadow bg-white p-4 mt-2" [formGroupName]="i">
                            <a *ngIf="testimonial().controls.length>1" href="javascript:void(0)" class="ms-2 close-btn"
                                (click)="removetestimonial(i)"><i class="uil-times fa-2x"></i></a>
                            <div class="my-2 row">
                                <div class="col-md-12">
                                    <label class="col-form-label">Icon</label>
                                    <div class=" d-flex">
                                        <input type="text" class="form-control" placeholder="URL"
                                            formControlName="image_url">
                                        <a [href]="testimonialForm.value.testimonial[i].image_url" target="_blank"><img
                                                class="ms-1" height="40"
                                                [src]="testimonialForm.value.testimonial[i].image_url"></a>
                                        <button class="btn btn-primary ms-1" type="button"
                                            (click)="openUploadModal(i, 'image_url')"><i
                                                class="fas fa-cloud-upload-alt"></i></button>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-3 col-md-8" *ngIf="page == 'b2b-home'">
                                <label class="col-form-label">Country</label>
                                <ng-select [(items)]="countryList" (change)="dataChanged(i)" [multiple]="false"
                                    [placeholder]="'Select Country'" bindLabel="name" bindValue="id"
                                    formControlName="country" [clearable]="false"></ng-select>
                            </div>
                            <div class="row">
                                <div class="mt-3 col-md-12">
                                    <label class="col-form-label">Description</label>
                                    <ckeditor [editor]="Editor" formControlName="description" (change)="dataChanged(i)"
                                        [ngClass]="{'invalid': item.get('description').invalid && isSubmitted}"
                                        data="<p>Content of the editor.</p>"></ckeditor>
                                </div>
                                <div class="mt-3 col-md-8">
                                    <label class="col-form-label">Author</label>
                                    <input type="text" class="form-control" placeholder="Author"
                                        formControlName="author" (change)="dataChanged(i)"
                                        [ngClass]="{'invalid': item.get('author').invalid && isSubmitted}">
                                </div>
                                <div class="mt-3 col-md-4">
                                    <label class="col-form-label">Stars</label>
                                    <input type="number" min="0" max="5" class="form-control" placeholder="Stars"
                                        formControlName="stars" (change)="dataChanged(i)"
                                        [ngClass]="{'invalid': item.get('stars').invalid && isSubmitted}">
                                </div>
                                <div class="mt-3 col-md-12">
                                    <div class="form-check form-switch form-switch-lg m-auto float-end" dir="ltr">
                                        <input type="checkbox" formControlName="is_starred" class="form-check-input"
                                            id="customSwitchsizelg" (change)="dataChanged(i)">
                                        <label class="col-form-label">Starred</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 p-5">
                        <div class="row">
                            <button *ngIf="checkPermission('content_management_home_create')"
                                class="btn btn-primary mt-1 col-md-12" type="button" (click)="addtestimonial()"><i
                                    class="uil-plus"></i> Add</button>
                            <button
                                *ngIf="checkPermission('content_management_home_create')&&checkPermission('content_management_home_edit')"
                                class="btn btn-info mt-1 col-md-12 mb-1" type="submit" [disabled]="isUpdating"><i
                                    [ngClass]="{'fa fa-pulse fa-spin fa-spinner': isUpdating,'bx bx-save': !isUpdating}"></i>
                                Save All</button>
                            <app-publish-btn *ngIf="checkPermission('content_management_home_publish')"
                                class="col-md-12 p-0" [publish]="'Testimonial'" [page]="'home'"></app-publish-btn>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>