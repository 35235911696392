<div class="container-fluid">

    <div class="my-3 row">
        <div class="offset-md-6 col-md-6 selection-box">
            <!-- <div class="selection">
                <ng-select [(items)]="countryList" [multiple]="false" [placeholder]="'Select Country'" bindLabel="name"
                    bindValue="id" [(ngModel)]="country" [clearable]="false"
                    (change)="selectCountry(country)"></ng-select>
            </div> -->

            <button class="btn btn-primary float-end" type="submit" (click)="openFormModal(categoryForm);"><i
                    class="bx bx-add"></i> Add
            </button>
        </div>
    </div>

    <div class="my-3 row">
        <div class="col-md-12" *ngIf="!categoriesList?.length && !isUpdating">
            <div class="text-center text-warning">No Category Found.</div>
        </div>

        <div class="col-md-12" *ngFor="let item of categoriesList">
            <div class="cat-card border mb-2 row">
                <span class="col-md-6">{{item.name}}</span>
                <span class="col-md-6 text-end">
                    <button class="btn btn-info mx-1" (click)="whileOpenModal(item);openFormModal(mappingForm, item);">
                        Add Country
                    </button>
                    <button class="btn btn-success mx-1" (click)="openFormModal(categoryForm, item);">
                        <i class="far fa-edit"></i>
                    </button>
                    <button class="btn btn-danger mx-1" (click)="openFormModal(categoryDelete, item);">
                        <i class="fas fa-trash"></i>
                    </button>
                </span>
            </div>
        </div>
    </div>
</div>

<ng-template #categoryForm let-modal>
    <form class="m-2" [formGroup]="catForm" (ngSubmit)="saveCategory(catForm)">
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-workFlow">Add/Update Category</h4>
            <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
        </div>
        <div class="modal-body">
            <div class="my-1 row">

                <div class="col-md-12">
                    <label class="col-form-label">Category Name</label>
                    <input type="text" class="form-control" placeholder="Name" formControlName="name"
                        [ngClass]="{'invalid': catForm.get('name').invalid && isSubmitted}">
                </div>
            </div>
            <div class="my-3 row">
                <div class="col-md-12">
                    <button class="btn btn-primary mt-1 float-end" type="submit" (click)="modal.close('Save click')"><i
                            class="bx bx-save"></i> Save
                    </button>
                </div>
            </div>
        </div>
    </form>
</ng-template>

<ng-template #mappingForm let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-workFlow">Add/Update Mapping</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
        <div class="my-1 row">

            <div class="col-md-12 mt-2" *ngFor="let item of selectedMapping; let i=index;">
                <div class="border shadow bg-white p-2 mt-1">
                    <a href="javascript:void(0)" class="ms-2 close-btn" (click)="removemapping(i)"><i
                            class="uil-times"></i></a>
                    <div class="row">
                        <div class="mt-2 col-md-6">
                            <label class="col-form-label" *ngIf="i==0">Country</label>
                            <div class="selection">
                                <ng-select [(items)]="countryList" [multiple]="false" [placeholder]="'Select Country'"
                                    bindLabel="name" bindValue="id" [(ngModel)]="item.country" [clearable]="false"
                                    (change)="item.is_updated = true;"></ng-select>
                            </div>
                        </div>
                        <div class="mt-2 col-md-6">
                            <label class="col-form-label" *ngIf="i==0">Sem Url</label>
                            <div class="selection">
                                <ng-select [(items)]="semData" [multiple]="false" [placeholder]="'Select Url'"
                                    bindLabel="url_params" bindValue="id" [(ngModel)]="item.url" [clearable]="false"
                                    (change)="item.is_updated = true;"></ng-select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="my-3 row">
            <div class="col-md-12">
                <button class="btn btn-primary mt-1 float-end" type="submit"
                    (click)="saveMapping();modal.close('Save click')"><i class="bx bx-save"></i> Save
                </button>
                <button class="btn btn-success mt-1 float-end me-2" type="button" (click)="addmapping()">
                    <i class="uil-plus"></i> Add Country
                </button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #categoryDelete let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-workFlow">Delete Category</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
        <div class="mt-2">
            <p>Are you sure to delete category <b>{{selectedCategoryData.name}}</b>?</p>
        </div>
        <div class="my-3 row">
            <div class="col-md-12">
                <button class="btn btn-danger mt-1 float-end" type="submit"
                    (click)="deleteCategory(); modal.dismiss('Cross click')"><i class="bx bx-save"></i> Delete
                </button>
                <button class="btn btn-secondary mt-1 float-end me-2" type="submit" (click)="modal.close('Delete')"><i
                        class="bx bx-save"></i> Cancel
                </button>
            </div>
        </div>
    </div>
</ng-template>