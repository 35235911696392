import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SharedService } from 'src/app/core/services/shared.service';
import { V3ServicesService } from 'src/app/core/services/v3-services.service';
import { ToastrService } from 'ngx-toastr';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-other-categories',
  templateUrl: './other-categories.component.html',
  styleUrls: ['./other-categories.component.scss']
})
export class OtherCategoriesComponent {

  // country;
  countryList = this.sharedService.cmsCountryList;
  imageUrl: any;
  imageId: number;
  isUpdating: boolean;
  categoriesList: any = [];
  semData = [];
  isSubmitted = false;
  selectedMapping = [];
  selectedCategory;
  selectedCategoryData;

  catForm: FormGroup;
  // mappingForm: FormGroup;
  deletedMapping: any;

  constructor(private sharedService: SharedService,
    private api: V3ServicesService,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private fb: FormBuilder) { }

  ngOnInit() {
    this.getSemData();
    this.resetForm();
    this.getCategories();
    if (this.countryList.length == 0) this.getCountriesList();
  }

  resetForm(data?) {
    this.catForm = this.fb.group({
      id: [data ? data.id : null],
      name: [data ? data.name : '', [Validators.required]],
      // sem_url: [data ? data.sem_url : '', [Validators.required]],
    })
  }

  // resetMappingForm() {
  //   this.mappingForm = this.fb.group({
  //     mapping: this.fb.array([])
  //   });
  // }

  // setMapData(result) {
  //   this.mappingForm = this.fb.group({
  //     mapping: new FormArray([]),
  //   });
  //   result.forEach(ao => {
  //     (this.mappingForm.get('mapping') as FormArray).push(
  //       this.fb.group({
  //         id: [ao.id],
  //         country: [ao.country, [Validators.required]],
  //         url: [ao.url, [Validators.required]],
  //         is_updated: [false],
  //         is_new: [false],
  //       })
  //     );
  //   });
  // }


  // mappings(): FormArray {
  //   return this.mappingForm.get("mapping") as FormArray
  // }

  // newmapping(): FormGroup {
  //   return this.fb.group({
  //     country: ['', [Validators.required]],
  //     url: ['', [Validators.required]],
  //     is_updated: [false],
  //     is_new: [true],
  //   })
  // }

  addmapping() {
    this.selectedMapping.push({
      country: '',
      url: '',
      is_updated: false,
      is_new: true,
    });
  }

  removemapping(i: number) {
    this.isSubmitted = false;
    this.selectedMapping[i].is_deleted = true;
    if (this.selectedMapping[i].hasOwnProperty("id")) this.deletedMapping.push(this.selectedMapping[i]);
    this.selectedMapping.splice(i, 1);
  }

  getCountriesList() {
    this.sharedService.getCmsCountryDetails().subscribe(
      (response: any) => {
        this.countryList = response;
        this.sharedService.cmsCountryList = this.countryList;
        // if (!this.country) this.country = this.countryList[0]?.id;
      })
  }

  getCategories() {
    this.api.getOtherCategories().subscribe((result: any) => {
      this.categoriesList = result;
    });
  }

  getSemData() {
    this.api.getSemData().subscribe((result: any) => {
      this.semData = result;
    });
  }

  getCatSemData(id) {
    this.api.getCategorySemData(id).subscribe((result: any) => {
      // this.semData = result;
      // this.setMapData(result?.mapping);
      result?.mapping.forEach(ae => {
        ae.is_updated = false;
        ae.is_new = false;
        ae.is_deleted = false;
      });
      this.selectedMapping = result?.mapping;
    });
  }

  getSemNameById(id) {
    return this.semData.find(ae => ae.id == id)?.url_params || '<span class="text-danger">No URL</span>'
  }

  openFormModal(template, category?) {
    this.resetForm(category);
    this.selectedCategoryData = category;
    this.modalService.open(template, { ariaLabelledBy: "modal-sm" });
  }

  saveCategory(form) {
    this.isSubmitted = true;

    if (form.valid) {
      this.isUpdating = true;
      // form.value.country = this.country;
      if (form.value.id) {
        this.api.updateSemData(form.value.id, form.value).subscribe(
          (result: any) => {
            this.resetForm(result);
            this.toastr.success("Category Updated Successfully!");
            this.getCategories();
            this.isUpdating = false;
          },
          () => {
            this.isUpdating = false;
            this.toastr.error("Error. Try Again!");
          }
        )
      } else {
        this.api.setSemData(form.value).subscribe(
          (result: any) => {
            this.resetForm(result);
            this.toastr.success("Category Added Successfully!");
            this.getCategories();
            this.isUpdating = false;
          },
          () => {
            this.isUpdating = false;
            this.toastr.error("Error. Try Again!");
          }
        )
      }
    } else {
      this.isSubmitted = true;
      this.toastr.warning("Fill all fields and Save");
    }
  }

  whileOpenModal(item) {
    this.deletedMapping = [];
    this.selectedMapping = [];
    this.selectedCategory = item.id;
    this.getCatSemData(item.id);
  }

  saveMapping() {
    this.deletedMapping.forEach(ae => {
      delete ae.is_updated;
      delete ae.is_new;
      this.selectedMapping.push(ae);
    })
    this.selectedMapping.forEach(ao => {
      if (!ao.is_deleted) delete ao.is_deleted;
      if (ao.is_new) delete ao.is_updated;
      if (!ao.is_new) delete ao.is_new;
    });

    this.api.setCategorySemData({
      category: this.selectedCategory,
      mapping: this.selectedMapping
    }).subscribe(
      () => {
        this.toastr.success("Content Updated Successfully!");
        this.isUpdating = false;
      },
      () => {
        this.isUpdating = false;
      })
  }

  deleteCategory() {
    this.api.deleteCategorySemData(this.selectedCategory).subscribe(
      () => {
        this.getCategories();
        this.toastr.success("Category Deleted Successfully!");
        this.isUpdating = false;
      },
      () => {
        this.isUpdating = false;
      })
  }
}
