<div class="container">
    <div class="row">
        <div class="col-md-12">
            <button class="btn btn-primary float-end mt-1" type="button" (click)="addYtlink()"><i class="uil-plus"></i>
                Add New</button>
        </div>
        <div class="spinner-border center_fixed_loader" role="status" *ngIf="isUpdating">
            <span class="sr-only"></span>
        </div>
        <div class="col-md-6 mt-2" *ngFor="let item of ytLinks; let i = index;">
            <div class="border shadow bg-white p-4 mt-2">
                <a href="javascript:void(0)" class="ms-2 close-btn" (click)="openDeleteModal(i, deleteModal)"><i
                        class="uil-times fa-2x"></i></a>
                <div class="my-2 row">
                    <div class="mt-2 col-md-12">
                        <label class="col-form-label">Description</label>
                        <textarea class="form-control" rows="3" placeholder="Description"
                            [(ngModel)]="item.description"></textarea>
                    </div>
                    <div class="mt-2 col-md-12">
                        <label class="col-form-label">Link</label>
                        <input type="text" class="form-control" placeholder="Link ID" [(ngModel)]="item.link_id">
                    </div>
                    <div class="mt-2 col-md-12">
                        <label class="col-form-label">Full Link</label>
                        <input type="text" class="form-control" placeholder="Full Link" [(ngModel)]="item.link">
                    </div>
                </div>
            </div>
            <div class="w-100">
                <button [disabled]="haveSameValues(i)" class="btn btn-primary float-end mt-1" type="button"
                    (click)="updateYtlink(i)"><i class="uil-plus"></i>
                    Save</button>
            </div>
        </div>
    </div>
</div>

<ng-template #deleteModal let-modal>
    <div class="modal-header">
        <h6 class="modal-title" id="modal-title">Delete Content</h6>
        <button type="button" class="btn-close" aria-describedby="modal-title"
            (click)="modal.close('Cross click')"></button>
    </div>
    <div class="modal-body">
        <p class="text-center">
            Are you sure to delete <strong class="text-primary">{{deleteLink?.name}}</strong>?
        </p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary" (click)="modal.close('cancel click')">Cancel</button>
        <button type="button" class="btn btn-danger" (click)="modal.close('ok')">Yes</button>
    </div>
</ng-template>