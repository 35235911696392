export const environment = {
  production: false,
  author: 'Elkanio',
  useHash: true,
  //apiUrl: 'http://192.168.10.12:8000',
  apiUrl: 'https://dev-api.stampthepassport.com',
  webUrl: 'https://dev-web.stampthepassport.com',
  countryFlagSrc: 'https://stp-data-bucket.s3.ap-south-1.amazonaws.com/general/countries',
  seoUrl: 'https://stp-stag-bucket.s3.ap-south-1.amazonaws.com/'
};


// export const environment = {
//   production: false,
//   author: 'Elkanio',
//   //apiUrl: 'http://192.168.10.12:8000',
//    apiUrl: 'http://127.0.0.1:8000',
//   webUrl: 'https://dev-web.stampthepassport.com',
//   // apiUrl: 'https://343b-103-214-235-22.in.ngrok.io',
//   countryFlagSrc: 'https://stp-data-bucket.s3.ap-south-1.amazonaws.com/general/countries',
//   seoUrl:'https://stp-stag-bucket.s3.ap-south-1.amazonaws.com/'
// };