<div class="container">
    <form [formGroup]="serviceForm" (ngSubmit)="onSubmit(serviceForm)">
        <div class="row">
            <div class="col-md-4 mt-2" formArrayName="services" *ngFor="let item of service().controls; let i = index;">
                <div class="border shadow bg-white p-4 mt-2" [formGroupName]="i">
                    <a *ngIf="service().controls.length>1" href="javascript:void(0)" class="ms-2 close-btn"
                        (click)="removeservice(i)"><i class="uil-times fa-2x"></i></a>
                    <div class="row">
                        <div class="mt-2  col-md-12">
                            <label class="col-form-label">Name</label>
                            <input type="text" class="form-control" placeholder="Name" formControlName="name"
                                (change)="dataChanged(i)"
                                [ngClass]="{'invalid': item.get('name').invalid && isSubmitted}">
                        </div>
                        <div class="mt-2 col-md-12">
                            <div class="form-check form-switch form-switch-lg m-auto" dir="ltr">
                                <input type="checkbox" formControlName="is_external" class="form-check-input"
                                    id="customSwitchsizelg" (change)="dataChanged(i)">
                                <label for="customSwitchsizelg" class="col-form-label">External</label>
                            </div>
                        </div>
                        <div class="mt-2 col-md-12">
                            <label class="col-form-label">Link URL</label>
                            <input type="text" class="form-control" placeholder="Link URL" formControlName="link"
                                (change)="dataChanged(i)"
                                [ngClass]="{'invalid': item.get('link').invalid && isSubmitted}">
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4 p-5">
                <div class="row">
                    <button class="btn btn-primary mt-1 me-1 col-md-12" type="button" (click)="addservice()">
                        <i class="uil-plus"></i> Add
                    </button>

                    <button class="btn btn-info mt-1 col-md-12" type="submit">
                        <i class="bx bx-save"></i> Save All
                    </button>
                </div>
            </div>
        </div>
    </form>
</div>