import { Injectable } from "@angular/core";
import { retry } from "rxjs/operators";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { environment } from "src/environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class SharedService {
  constructor(private http: HttpClient) { }

  countryList: any = [];
  countryAllData: any = [];
  statesList = [];
  selectedCountryId: number = null;
  addedCountryList = [];

  selectedCmsCountryId: number = null;
  selectedCmsCategoryId: number = null;
  selectedCmsProductId: number = null;

  cmsCountryList = [];
  cmsCategoryList = [];
  cmsProductList = [];

  selectedUserPermissionData = {};

  globalQue: any = null;
  formCountryList: any = null;
  private backToList = new BehaviorSubject<string>("");
  backToList$ = this.backToList.asObservable();
  private showCatSubCatTitle = new BehaviorSubject<boolean>(false);
  showCatSubCatTitle$ = this.showCatSubCatTitle.asObservable();
  private profileDropdowns = new BehaviorSubject('');
  profileDropdowns$ = this.profileDropdowns.asObservable();
  tableSearchOrValidate: Subject<string> = new Subject();
  tableSearchOrValidate$ = this.tableSearchOrValidate.asObservable();

  setProfileDropDowns(value: any) {
    this.profileDropdowns.next(value);
  }

  setTableSearchOrValidateData(value: string) {
    this.tableSearchOrValidate.next(value)
  }

  setValidateUrl
  changeCatTitle(value: boolean) {
    this.showCatSubCatTitle.next(value);
  }

  backTolist() {
    this.backToList.next("proceed");
  }
  uploadFile(data): Observable<any> {
    // const headers = new HttpHeaders({ 'Content-Type': 'multipart/form-data' });, { headers }
    return this.http.post(
      `${environment.apiUrl}/cms-admin/common/file-upload/`,
      data,
      {
        reportProgress: true,
        observe: "events",
      }
    );
  }

  setCountryId(id) {
    this.selectedCountryId = id;
  }

  getCountryDetails(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/country/`).pipe(retry(2));
  }

  getCmsCountryDetails(): Observable<any> {
    return this.http
      .get(`${environment.apiUrl}/cms-admin/common/countries/`)
      .pipe(retry(2));
  }

  getCmsCategoryList(country): Observable<any> {
    return this.http
      .get(
        `${environment.apiUrl}/cms-admin/common/categories/?country=${country}`
      )
      .pipe(retry(2));
  }

  getCmsProductList(category): Observable<any> {
    return this.http
      .get(
        `${environment.apiUrl}/cms-admin/common/products/?category=${category}`
      )
      .pipe(retry(2));
  }

  popularStarRating(data): Observable<any> {
    return this.http.post(
      `${environment.apiUrl}/cms-admin/country/product-detail/star-rating/`,
      data
    );
  }

  bulkPublish(data): Observable<any> {
    return this.http.post(
      `${environment.apiUrl}/cms-admin/common/bulk-publish/`,
      data
    );
  }

  getCmsSubCategoryDataById(product): Observable<any> {
    return this.http
      .get(
        `${environment.apiUrl}/cms-admin/country/product-detail/?product=${product}`
      )
      .pipe(retry(2));
  }

  getCmsSubCategoryDataByCatId(category): Observable<any> {
    return this.http
      .get(
        `${environment.apiUrl}/cms-admin/country/product-detail/?product__category=${category}`
      )
      .pipe(retry(2));
  }

  checkUrlParam(data): Observable<any> {
    return this.http.post(
      `${environment.apiUrl}/cms-admin/common/url-param-validation/`,
      data
    );
  }

  generalPutApi(data, url): Observable<any> {
    return this.http.put(`${environment.apiUrl}/${url}/`, data);
  }

  setKeywords(data): Observable<any> {
    return this.http.post(
      `${environment.apiUrl}/cms-admin/country-keywords/country-keyword/`,
      data
    );
  }

  getKeywords(): Observable<any> {
    return this.http
      .get(`${environment.apiUrl}/cms-admin/country-keywords/country-keyword/`)
      .pipe(retry(2));
  }

  getGallery(limit, offset): Observable<any> {
    return this.http
      .get(
        `${environment.apiUrl}/gallery/` +
        `?limit=${limit}` +
        `&offset=${offset}`
      )
      .pipe(retry(2));
  }

  galleryDetails(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/gallery-details/`, data);
  }
}
