<div class="container">
    <form class="row" [formGroup]="snackForm" (ngSubmit)="onSubmit(snackForm)">
        <div class="col-md-8 offset-md-2 p-4 mt-2">
            <div class="border shadow p-3">
                <div class="my-2 mt-3 row">
                    <label class="col-form-label">Text</label>
                    <div class="d-flex">
                        <ckeditor class="w-100" [config]="editorConfig" [editor]="Editor" formControlName="text"
                            [ngClass]="{'invalid': snackForm.get('text').invalid && isSubmitted}"
                            data="<p>Content of the editor.</p>">
                        </ckeditor>
                    </div>
                </div>

                <div class="my-2 row">
                    <label class="col-form-label">Icon URL</label>
                    <div class="d-flex">
                        <input type="text" class="form-control" placeholder="URL" formControlName="icon">
                        <a [href]="snackForm.value.icon" target="_blank"><img class="ms-1" height="40"
                                [src]="snackForm.value.icon"></a>
                        <button class="btn btn-primary ms-1" type="button" (click)="openUploadModal('icon')"><i
                                class="fas fa-cloud-upload-alt"></i></button>
                    </div>
                    <small class="text-info">Image ratio 1:1 preferred</small>
                </div>

                <div class="my-2 row">
                    <div class="col-md-6 pt-3">
                        <div class="form-check form-switch form-switch-lg m-auto" dir="ltr">
                            <input type="checkbox" formControlName="is_enabled" class="form-check-input"
                                id="customSwitchsizelg">
                            <label for="customSwitchsizelg" class="col-form-label">Enabled</label>
                        </div>
                    </div>
                </div>

                <div class="my-2 row">
                    <div class="col-md-12">
                        <button class="btn btn-info float-end">
                            <i [ngClass]="{'fa fa-pulse fa-spin fa-spinner': isUpdating,'bx bx-save': !isUpdating}"></i>
                            Save Changes</button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>