<div class="container">
    <form class="row" [formGroup]="expertForm" (ngSubmit)="onSubmit(expertForm)">
        <div class="col-md-8 offset-md-2 p-4 mt-2">
            <div class="border shadow p-3">
                <div class="my-2 mt-3 row">
                    <label class="col-form-label">Name</label>
                    <div class="d-flex">
                        <input type="text" class="form-control" placeholder="Enter Name" formControlName="name"
                            [ngClass]="{'invalid': expertForm.get('name').invalid && isSubmitted}">
                    </div>
                </div>

                <div class="my-2 mt-3 row">
                    <label class="col-form-label">Description</label>
                    <div class="d-flex">
                        <textarea class="form-control" rows="4" placeholder="Enter Description"
                            formControlName="description"
                            [ngClass]="{'invalid': expertForm.get('description').invalid && isSubmitted}"></textarea>
                    </div>
                </div>

                <div class="my-2 row">
                    <label class="col-form-label">Image URL</label>
                    <div class="d-flex">
                        <input type="text" class="form-control" placeholder="URL" formControlName="photo">
                        <a [href]="expertForm.value.photo" target="_blank"><img class="ms-1" height="40"
                                [src]="expertForm.value.photo"></a>
                        <button class="btn btn-primary ms-1" type="button" (click)="openUploadModal('photo')"><i
                                class="fas fa-cloud-upload-alt"></i></button>
                    </div>
                    <small class="text-info">Image ratio 1:1 preferred</small>
                </div>

                <div class="my-2 mt-3 row">
                    <div class="col-md-6">
                        <label class="col-form-label">Visa Approved</label>
                        <div class="d-flex">
                            <input type="text" class="form-control" placeholder="Enter Visa Approved"
                                formControlName="visa_approved"
                                [ngClass]="{'invalid': expertForm.get('visa_approved').invalid && isSubmitted}">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <label class="col-form-label">Approval Rate</label>
                        <div class="d-flex">
                            <input type="text" class="form-control" placeholder="Enter Approval Rate"
                                formControlName="approval_rate"
                                [ngClass]="{'invalid': expertForm.get('approval_rate').invalid && isSubmitted}">
                        </div>
                    </div>
                </div>

                <div class="my-2 mt-3 row">
                    <div class="col-md-6">
                        <label class="col-form-label">Experience</label>
                        <div class="d-flex">
                            <input type="text" class="form-control" placeholder="Enter Visa Approved"
                                formControlName="experience"
                                [ngClass]="{'invalid': expertForm.get('experience').invalid && isSubmitted}">
                        </div>
                    </div>
                    <div class="col-md-6 pt-5">
                        <div class="form-check form-switch form-switch-lg m-auto" dir="ltr">
                            <input type="checkbox" formControlName="is_enabled" class="form-check-input"
                                id="customSwitchsizelg">
                            <label for="customSwitchsizelg" class="col-form-label">Enabled</label>
                        </div>
                    </div>
                </div>

                <div class="my-2 row">
                    <div class="col-md-12">
                        <button class="btn btn-info float-end">
                            <i [ngClass]="{'fa fa-pulse fa-spin fa-spinner': isUpdating,'bx bx-save': !isUpdating}"></i>
                            Save Changes</button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>