<div class="container">
    <form [formGroup]="benefitForm" (ngSubmit)="onSubmit(benefitForm)">
        <div class="row">
            <div class="col-md-4 mt-2" formArrayName="benefits" *ngFor="let item of benefit().controls; let i = index;">
                <div class="border shadow bg-white p-4 mt-2" [formGroupName]="i">
                    <a href="javascript:void(0)" class="ms-2 close-btn" (click)="removebenefit(i)"><i
                            class="uil-times fa-2x"></i></a>
                    <div class="row">
                        <div class="mt-2  col-md-12">
                            <label class="col-form-label">Benefit</label>
                            <input type="text" class="form-control" placeholder="Benefit" formControlName="benefit"
                                (change)="dataChanged(i)"
                                [ngClass]="{'invalid': item.get('benefit').invalid && isSubmitted}">
                        </div>
                        <div class="mt-2  col-md-12">
                            <label class="col-form-label">Icon URL</label>
                            <div class=" d-flex">
                                <input type="text" class="form-control" placeholder="Icon Url" formControlName="icon">
                                <a [href]="benefitForm.value.benefits[i].icon" target="_blank">
                                    <img class="ms-1" height="40" [src]="benefitForm.value.benefits[i].icon">
                                </a>
                                <button class="btn btn-primary ms-1" type="button"
                                    (click)="openUploadModal(i, 'icon')"><i
                                        class="fas fa-cloud-upload-alt"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4 p-5">
                <div class="row">
                    <button class="btn btn-primary mt-1 me-1 col-md-12" type="button" (click)="addbenefit()">
                        <i class="uil-plus"></i> Add
                    </button>

                    <button class="btn btn-info mt-1 col-md-12" type="submit">
                        <i class="bx bx-save"></i> Save All
                    </button>
                </div>
            </div>
        </div>
    </form>
</div>