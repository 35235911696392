import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { UIModule } from '../../shared/ui/ui.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbNavModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { NgWizardModule, NgWizardConfig, THEME } from 'ng-wizard';
import { NgSelectModule } from '@ng-select/ng-select';

import { CountryComponent } from './country/country.component';
import { JurisdictionComponent } from './jurisdiction/jurisdiction.component';
import { ProductsComponent } from './products/products.component';
import { CategoryComponent } from './category/category.component';
import { AgeGroupComponent } from './age-group/age-group.component';
import { AddCountryComponent } from './country/add-country/add-country.component';
import { CountryDetailsComponent } from './country/add-country/country-details/country-details.component';
import { JurisdictionDetailsComponent } from './country/add-country/jurisdiction-details/jurisdiction-details.component';
import { AgeGroupsComponent } from './country/add-country/age-groups/age-groups.component';
import { TermDetailsComponent } from './country/add-country/term-details/term-details.component';
import { FeeDetailsComponent } from './country/add-country/fee-details/fee-details.component';
import { RelevantProductsComponent } from './country/add-country/relevant-products/relevant-products.component';
import { RequiredDocsComponent } from './required-docs/required-docs.component';
import { PricingComponent } from './pricing/pricing.component';
import { CategoryDetailsComponent } from './country/add-country/category-details/category-details.component';
import { SortCategoryComponent } from './country/add-country/sort-category/sort-category.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AddOnComponent } from './add-on/add-on.component';
import { CoreModule } from 'src/app/core/core.module';
import { AddOnInsuranceComponent } from './add-on-insurance/add-on-insurance.component';
import { AddOnHotelFlightComponent } from './add-on-hotel-flight/add-on-hotel-flight.component';
import { AddEditCategoryModalComponent } from './country/add-country/add-edit-category-modal/add-edit-category-modal.component';
import { SubCategoryComponent } from './country/add-country/sub-category/sub-category.component';
import { SubCategoryModalComponent } from './country/add-country/sub-category-modal/sub-category-modal.component';
import { AddonCourierComponent } from './addon-courier/addon-courier.component';
import { AddOnAddonComponent } from './add-on-addon/add-on-addon.component';
import { CKEditorModule } from "@ckeditor/ckeditor5-angular";

const routes: Routes = [
  { path: 'country', component: CountryComponent },
  { path: 'jurisdiction', component: JurisdictionComponent },
  { path: 'products', component: ProductsComponent },
  { path: 'category', component: CategoryComponent },
  { path: 'age-group', component: AgeGroupComponent },
  { path: 'pricing', component: PricingComponent },
  { path: 'required-docs', component: RequiredDocsComponent },
  { path: 'add-on', component: AddOnComponent }
];

const ngWizardConfig: NgWizardConfig = {
  theme: THEME.default
};
@NgModule({
  declarations: [
    CountryComponent,
    JurisdictionComponent,
    ProductsComponent,
    CategoryComponent,
    AgeGroupComponent,
    AddCountryComponent,
    CountryDetailsComponent,
    JurisdictionDetailsComponent,
    AgeGroupsComponent,
    TermDetailsComponent,
    FeeDetailsComponent,
    RelevantProductsComponent,
    RequiredDocsComponent,
    PricingComponent,
    CategoryDetailsComponent,
    SortCategoryComponent,
    AddOnComponent,
    AddOnInsuranceComponent,
    AddOnHotelFlightComponent,
    AddEditCategoryModalComponent,
    SubCategoryComponent,
    SubCategoryModalComponent,
    AddonCourierComponent,
    AddOnAddonComponent
  ],
  imports: [
    CommonModule,
    UIModule,
    FormsModule,
    DragDropModule,
    ReactiveFormsModule,
    NgbPaginationModule,
    NgSelectModule,
    NgbNavModule,
    CoreModule,
    CKEditorModule,
    RouterModule.forChild(routes),
    NgWizardModule.forRoot(ngWizardConfig),

  ],
  exports: [RouterModule]
})
export class ProductsModule { }
