import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import { V3ServicesService } from 'src/app/core/services/v3-services.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-footer-services',
  templateUrl: './footer-services.component.html',
  styleUrls: ['./footer-services.component.scss']
})
export class FooterServicesComponent {

  serviceForm: FormGroup;
  hasChanges = false;
  isSubmitted = false;

  isUpdating = false;
  deletedService = [];

  constructor(private fb: FormBuilder,
    private toastr: ToastrService,
    private api: V3ServicesService) { }

  ngOnInit(): void {
    this.resetForm();
    this.getFooterServices();
  }

  resetForm() {
    this.serviceForm = this.fb.group({
      services: this.fb.array([])
    });
  }

  getFooterServices() {
    this.api.getFooterServices().subscribe(
      (result: any) => {
        this.setData(result);
      })
  }

  setData(result) {
    this.serviceForm = this.fb.group({
      services: new FormArray([]),
    });
    result.forEach(ao => {
      (this.serviceForm.get('services') as FormArray).push(
        this.fb.group({
          id: [ao.id],
          name: [ao.name, [Validators.required]],
          link: [ao.link, [Validators.required]],
          is_external: [ao.is_external ?? false],
          is_updated: [false],
          is_new: [false],
        })
      );
    });
  }

  service(): FormArray {
    return this.serviceForm.get("services") as FormArray
  }

  newservice(): FormGroup {
    return this.fb.group({
      name: ['', [Validators.required]],
      link: ['', [Validators.required]],
      is_external: [false],
      is_updated: [false],
      is_new: [true],
    })
  }

  addservice() {
    this.service().push(this.newservice());
  }

  removeservice(i: number) {
    this.isSubmitted = false;
    this.serviceForm.value.services[i].is_deleted = true;
    if (this.serviceForm.value.services[i].hasOwnProperty("id")) this.deletedService.push(this.serviceForm.value.services[i]);
    this.service().removeAt(i);
  }

  dataChanged(i) {
    let ae = this.serviceForm.value.services[i];
    if (ae.is_new) {
      delete ae.is_updated;
    } else {
      ae.is_updated = true;
      delete ae.is_new;
    }

  }

  onSubmit(form) {

    if (form.valid) {
      this.isUpdating = true;
      this.deletedService.forEach(ae => {
        delete ae.is_updated;
        delete ae.is_new;
        form.value.services.push(ae);
      })

      this.api.setFooterService(form.value.services).subscribe(
        () => {
          this.toastr.success("Content Updated Successfully!");
          this.getFooterServices();
          this.hasChanges = false;
          this.isUpdating = false;
        },
        () => {
          this.isUpdating = false;
        })
    }
  }

}
