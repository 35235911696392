import { Component, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CountryManagementService } from 'src/app/core/services/country-management.service';
import { ToastrService } from 'ngx-toastr';
import { PermissionService } from 'src/app/core/services/permision.service';
import { UploadGalleryComponent } from 'src/app/shared/ui/upload-gallery/upload-gallery.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-country-header',
  templateUrl: './country-header.component.html',
  styleUrls: ['./country-header.component.scss']
})
export class CountryHeaderComponent {

  headerForm: FormGroup;
  hasChanges = false;
  isUpdating = false;
  @Input() country;
  @Input() page;
  isNew = false;
  isSubmitted = false;
  publishPage: string;

  constructor(private fb: FormBuilder,
    private api: CountryManagementService, private toastr: ToastrService,
    private modalService: NgbModal, public permission: PermissionService) {

  }

  ngOnInit(): void {
    this.resetForm();
    this.getData();
    this.publishPage = 'CountryDescription';
  }

  resetForm(data?) {
    this.headerForm = this.fb.group({
      id: [data ? data.id : null],
      heading: [data ? data.heading : '', [Validators.required]],
      sub_heading: [data ? data.sub_heading : '', [Validators.required]],
      description: ['-'],
      banner_image_url: [data ? data.banner_image_url : ''],
      mob_banner_image_url: [data ? data.mob_banner_image_url : ''],
      amount: [0],
      button_label: ['-'],
      button_url: ['-'],
      country: [this.country],
      currency_symbol: ['-'],
      price_label: ['-'],

      country_banner_image_url: [data?.country_banner_image_url || '-'],
      // country_mob_banner_image_url: [data ? data.country_mob_banner_image_url : '', [Validators.required]],

      country_banner_button_url: [data?.country_banner_button_url || '-'],
      sub_heading_icon_url: [data?.sub_heading_icon_url || '-'],
      count_on_us_url: [data?.count_on_us_url || '-'],
      faq_url: [data?.faq_url || '-'],
    })

    this.onChanges();

  }

  onChanges(): void {
    this.headerForm.valueChanges.subscribe(() => {
      Object.keys(this.headerForm.controls).map((key) => {
        if (this.headerForm.get(key).dirty) this.hasChanges = true;
      })
    });
  }

  // handleIcon(files: FileList) {
  //   this.hasChanges = true;
  //   this.fileToUpload = files.item(0);
  //   let unsafeImageUrl = URL.createObjectURL(this.fileToUpload);
  //   this.headerForm.value.banner_image_url = this.sanitizer.bypassSecurityTrustUrl(unsafeImageUrl);
  // }

  onSubmit(form) {
    if (form.valid) {
      this.isUpdating = true;
      // const formData: FormData = new FormData();
      // Object.keys(this.headerForm.controls).map((key) => {
      //   if (key == 'banner_image_url' && this.fileToUpload) formData.append('banner_image_url', this.fileToUpload);
      //   else formData.append(key, this.headerForm.value[key]);
      // })

      // if (this.fileToUpload == null) formData.delete('banner_image_url');

      if (this.isNew) {
        this.api.postDescription(form.value).subscribe(
          (result: any) => {
            this.resetForm(result);
            this.toastr.success("Content Added Successfully!");
            this.hasChanges = false;
            this.isUpdating = false;
            this.isNew = false;
          },
          () => {
            this.isUpdating = false;
          }
        )
      } else {
        this.api.setDescription(form.value.id, form.value).subscribe(
          (result: any) => {
            this.resetForm(result);
            this.toastr.success("Content Updated Successfully!");
            this.hasChanges = false;
            this.isUpdating = false;
          },
          () => {
            this.isUpdating = false;
          }
        )
      }
    } else {
      this.isSubmitted = true;
      this.toastr.warning("Fill all fields and Save!");
    }

  }

  getData() {
    this.api.getDescription(this.country).subscribe(
      (result: any) => {
        this.resetForm(result[0]);
        if (result.length == 0) this.isNew = true;
        else this.isNew = false;
      })
  }

  checkPermission(type) {
    return this.permission.hasPermission(type);
  }

  openUploadModal(field) {
    const modalRef = this.modalService.open(UploadGalleryComponent,
      {
        size: 'xl',
        scrollable: true,
        windowClass: 'modal-big-lg',
        keyboard: false,
        backdrop: 'static'
      });
    modalRef.componentInstance.uploadDetails = {};
    modalRef.result.then((result) => {
      if (result.status) {
        this.headerForm.get(field).setValue(result.image);
      }
    });
  }
}
