import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HomeManagementService } from 'src/app/core/services/home-management.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { SharedService } from 'src/app/core/services/shared.service';
import { PermissionService } from 'src/app/core/services/permision.service';
import * as ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CustomSortComponent } from '../../ui-elements/custom-sort/custom-sort.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UploadGalleryComponent } from 'src/app/shared/ui/upload-gallery/upload-gallery.component';
import { CommonModule } from '@angular/common';
import { NgSelectModule } from "@ng-select/ng-select";
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';

@Component({
  selector: 'app-testimonials',
  standalone: true,
  templateUrl: './testimonials.component.html',
  styleUrls: ['./testimonials.component.scss'],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    CKEditorModule
  ]
})
export class TestimonialsComponent implements OnInit {
  public Editor = ClassicEditor;
  testimonialForm: FormGroup;
  hasChanges = false;
  isUpdating = false;
  deletedtestimonial = [];
  isSubmitted = false;
  country = this.sharedService.selectedCmsCountryId;
  countryList = this.sharedService.cmsCountryList;
  @Input() page;
  // dummy_img = 'https://stp-data-bucket-staging.s3.amazonaws.com/cms_dev/documents/year-of-dragon_0TcmrqN.png';

  constructor(private fb: FormBuilder, private api: HomeManagementService, public permission: PermissionService,
    private sanitizer: DomSanitizer, private toastr: ToastrService, private sharedService: SharedService, private modalService: NgbModal) { }

  ngOnInit(): void {
    this.resetForm();
    if (this.sharedService.selectedCmsCountryId || this.page == 'b2b-home') this.getUsptestimonial();
    if (this.countryList.length == 0) this.getCountriesList();
  }

  resetForm() {
    this.testimonialForm = this.fb.group({
      testimonial: this.fb.array([
        this.fb.group({
          id: ['', [Validators.required]],
          image_url: ['', [Validators.required]],
          heading: ['-'],
          description: ['', [Validators.required]],
          author: ['', [Validators.required]],
          stars: ['', [Validators.required]],
          is_starred: [false, [Validators.required]],
          global_starred: [true],
          country: [this.page == 'b2b-home' ? null : this.country, [Validators.required]],
          priority: [0, [Validators.required]],
          is_updated: [''],
          b2b: [this.page == 'b2b-home']
        })])
    });
  }

  getUsptestimonial() {
    this.api.getTestimonials(this.page == 'b2b-home' ? { b2b: true } : { country: this.country }).subscribe(
      (result: any) => {
        console.log(result);
        this.testimonialForm = this.fb.group({
          testimonial: new FormArray([]),
        });

        result.forEach((ao, i) => {
          (this.testimonialForm.get('testimonial') as FormArray).push(
            this.fb.group({
              id: [ao.id, [Validators.required]],
              image_url: [ao.image_url, [Validators.required]],
              heading: ['-'],
              description: [ao.description, [Validators.required]],
              author: [ao.author, [Validators.required]],
              stars: [ao.stars, [Validators.required]],
              is_starred: [ao.is_starred, [Validators.required]],
              priority: [i, [Validators.required]],
              global_starred: [true],
              country: [this.page == 'b2b-home' ? ao.country : this.country, [Validators.required]],
              is_updated: [false],
              b2b: [this.page == 'b2b-home']
            })
          );
          this.deletedtestimonial = [];
        });
      })
  }

  testimonial(): FormArray {
    return this.testimonialForm.get("testimonial") as FormArray
  }

  newtestimonial(): FormGroup {
    return this.fb.group({
      image_url: ['', [Validators.required]],
      heading: ['-'],
      description: ['', [Validators.required]],
      author: ['', [Validators.required]],
      stars: ['', [Validators.required]],
      is_starred: [false, [Validators.required]],
      global_starred: [true],
      country: [this.page == 'b2b-home' ? null : this.country, [Validators.required]],
      priority: [0, [Validators.required]],
      is_updated: [true],
      b2b: [this.page == 'b2b-home']
    })
  }

  addtestimonial() {
    this.testimonial().push(this.newtestimonial());
  }

  removetestimonial(i: number) {
    this.isSubmitted = false;
    this.testimonialForm.value.testimonial[i].is_deleted = true;
    if (this.testimonialForm.value.testimonial[i].hasOwnProperty("id")) this.deletedtestimonial.push(this.testimonialForm.value.testimonial[i]);
    this.testimonial().removeAt(i);
  }

  onSubmit(form) {
    if (form.valid) {
      this.isUpdating = true;
      this.deletedtestimonial.forEach(ae => {
        ae.is_updated = false;
        form.value.testimonial.push(ae);
      })
      if (this.page != 'b2b-home') {
        form.value.testimonial.forEach(ae => {
          ae.country = this.country;
        });
      }
      this.api.setTestimonials(form.value.testimonial).subscribe(
        () => {
          this.toastr.success("Content Updated Successfully!");
          this.getUsptestimonial();
          this.hasChanges = false;
          this.isUpdating = false;
        },
        () => {
          this.isUpdating = false;
        }
      )
    } else {
      this.isSubmitted = true;
      this.toastr.warning("Fill all fields and Save!");
    }
  }

  dataChanged(i) {
    this.testimonialForm.value.testimonial[i].is_updated = true;
  }

  checkPermission(type) {
    return this.permission.hasPermission(type);
  }

  getCountriesList() {
    this.sharedService.getCmsCountryDetails().subscribe(
      (response: any) => {
        this.countryList = response;
        this.sharedService.cmsCountryList = this.countryList;
      })
  }


  selectCountry(country) {
    this.getUsptestimonial();
    this.country = country;
    this.sharedService.selectedCmsCountryId = country;
    this.sharedService.selectedCmsCategoryId = null;
    this.sharedService.cmsCategoryList = [];

    this.sharedService.selectedCmsProductId = null;
    this.sharedService.cmsProductList = [];
    // this.getCategories(country);
  }

  getCategories(country_id) {
    this.sharedService.getCmsCategoryList(country_id).subscribe(
      (result: any) => {
        this.sharedService.cmsCategoryList = result;
      })
  }

  showSortModal(data, label) {
    let data_card: any = {
      list: data,
      label: label,
      api_url: 'cms-admin/testimonial/testimonial-priority/position'
    };
    const modalRef = this.modalService.open(CustomSortComponent,
      {
        size: 'md',
        scrollable: true,
        windowClass: 'modal-big-lg',
        keyboard: false,
        backdrop: 'static'
      });
    modalRef.componentInstance.sortData = data_card;
    modalRef.result.then((result) => {
      if (result) this.getUsptestimonial();
    });
  }

  openUploadModal(i, field) {
    const modalRef = this.modalService.open(UploadGalleryComponent,
      {
        size: 'xl',
        scrollable: true,
        windowClass: 'modal-big-lg',
        keyboard: false,
        backdrop: 'static'
      });
    modalRef.componentInstance.uploadDetails = {};
    modalRef.result.then((result) => {
      if (result.status) {
        this.testimonialForm.get(['testimonial', i, field]).setValue(result.image);
        this.testimonialForm.get(['testimonial', i, 'is_updated']).setValue(true);
      }
    });
  }

}
