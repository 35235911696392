import { Component, OnInit } from '@angular/core';
import { SharedService } from 'src/app/core/services/shared.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-country-page',
  templateUrl: './country-page.component.html',
  styleUrls: ['./country-page.component.scss']
})
export class CountryPageComponent implements OnInit {

  breadCrumbItems: Array<{}>;
  page = 'country';
  country = this.sharedService.selectedCmsCountryId;
  countryList = this.sharedService.cmsCountryList;

  constructor(private sharedService: SharedService, private router: Router) { }

  ngOnInit() {
    this.breadCrumbItems = [{ label: 'CMS' }, { label: 'Country Page', active: true }];
    if (this.countryList.length == 0) this.getCountriesList();
  }

  getCountriesList() {
    this.sharedService.getCmsCountryDetails().subscribe(
      (response: any) => {
        this.countryList = response;
        this.sharedService.cmsCountryList = this.countryList;
      })
  }

  selectCountry(country) {
    this.sharedService.selectedCmsCountryId = country;
    this.sharedService.cmsCategoryList = [];

    this.sharedService.selectedCmsCategoryId = null;
    this.sharedService.cmsCategoryList = [];

    this.sharedService.selectedCmsProductId = null;
    this.sharedService.cmsProductList = [];
    const currentUrl = this.router.url;
    this.router.navigate([this.router.url]);
    this.router.navigateByUrl('/products/country', { skipLocationChange: true }).then(() =>
      this.router.navigateByUrl(currentUrl));
  }

}
