<div class="container">
    <form [formGroup]="partnerForm" (ngSubmit)="onSubmit(partnerForm)">
        <div class="row">
            <div class="col-md-4 mt-2" formArrayName="partners"
                *ngFor="let item of partners().controls; let i = index;">
                <div class="example-handle" cdkDragHandle></div>
                <div class="example-custom-placeholder" *cdkDragPlaceholder></div>
                <div class="border shadow bg-white p-4 mt-2" [formGroupName]="i">
                    <a *ngIf="partners().controls.length>1" href="javascript:void(0)" class="ms-2 close-btn"
                        (click)="removepartners(i)"><i class="uil-times fa-2x"></i></a>
                    <div class="my-2 row">
                        <div class="col-md-12">
                            <label class="col-form-label">Image URL</label>
                            <div class=" d-flex">
                                <input type="text" class="form-control" placeholder="URL" formControlName="icon_url"
                                    (change)="dataChanged(i)">
                                <a [href]="partnerForm.value.partners[i].icon_url" target="_blank"><img class="ms-1"
                                        height="40" [src]="partnerForm.value.partners[i].icon_url"></a>
                                <button class="btn btn-primary ms-1" type="button" (click)="openUploadModal(i)"><i
                                        class="fas fa-cloud-upload-alt"></i></button>
                            </div>
                        </div>
                    </div>
                    <div class="mt-3 row">
                        <div class="col-md-6">
                            <div class="form-check form-switch form-switch-lg m-auto float-start" dir="ltr">
                                <label class="col-form-label cpr" for="customStarred{{i}}">Starred</label>
                                <input type="checkbox" formControlName="is_starred" class="form-check-input mt-2"
                                    id="customStarred{{i}}" (change)="dataChanged(i)">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-check form-switch form-switch-lg m-auto float-end" dir="ltr">
                                <label class="col-form-label cpr" for="customPriority{{i}}">Priority</label>
                                <input type="checkbox" formControlName="is_priority" class="form-check-input mt-2"
                                    id="customPriority{{i}}" (change)="dataChanged(i)">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4 d-flex p-5">
                <div class="m-auto">
                    <button
                        *ngIf="(page=='home' && checkPermission('content_management_home_create')) || (page=='consultant' && checkPermission('content_management_consultant_create'))"
                        class="btn btn-primary mt-1 col-md-12" type="button" (click)="addpartners()"><i
                            class="uil-plus"></i> Add</button>
                    <button
                        *ngIf="(page=='home' && checkPermission('content_management_home_create')) || (page=='consultant' && checkPermission('content_management_consultant_create')) && 
                        (page=='home' && checkPermission('content_management_home_edit')) || (page=='consultant' && checkPermission('content_management_consultant_edit'))"
                        class="btn btn-info mt-1 mb-1 col-md-12" type="submit"><i
                            [ngClass]="{'fa fa-pulse fa-spin fa-spinner': isUpdating,'bx bx-save': !isUpdating}"></i>
                        Save All</button>
                    <app-publish-btn *ngIf="(page=='home' && checkPermission('content_management_home_publish')) || 
                    (page=='consultant' && checkPermission('content_management_consultant_publish'))"
                        class="col-md-12 p-0" [publish]="'TrustedPartner'" [page]="page"></app-publish-btn>
                </div>
            </div>
        </div>
    </form>
</div>