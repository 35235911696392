import { Injectable } from '@angular/core';
import { retry } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class MediaService {

  constructor(private http: HttpClient) { }

  //#region :- blog
  getAuthor(mediaType: number): Observable<any> {
    return this.http.get(`${environment.apiUrl}/cms-admin/useful_media/author/?media_type_id=${mediaType}`).pipe(retry(2));
  }

  setAuthor(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/cms-admin/useful_media/author/`, data).pipe(retry(2));
  }



  getUsefulMedia(mediaType: number): Observable<any> {
    return this.http.get(`${environment.apiUrl}/cms-admin/useful_media/useful-media/?media_type_id=${mediaType}`).pipe(retry(2));
  }

  setUsefulMedia(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/cms-admin/useful_media/useful-media/`, data);
  }

  getSpecificMedia(mediaType: number, pagination: any): Observable<any> {
    // return this.http.get(`${environment.apiUrl}/cms-admin/useful_media/useful-media-additional/?media_type_id=${mediaType}`).pipe(retry(2));

    return this.http.get(`${environment.apiUrl}/cms-admin/useful_media/specific_blog_additional/?media_type_id=${mediaType}&limit=${pagination.limit}&offset=${pagination.current}`).pipe(retry(2));

    // return https://dev-api.stampthepassport.com/cms-admin/useful_media/specific_blog_additional/

  }

  setSpecificMedia(data): Observable<any> {
    // return this.http.post(`${environment.apiUrl}/cms-admin/useful_media/useful-media-additional/`, data).pipe(retry(2));
    return this.http.post(`${environment.apiUrl}/cms-admin/useful_media/specific_blog_additional/`, data).pipe(retry(2));
  }

  getBlogHeader(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/cms-admin/useful_media/blog_description/`).pipe(retry(2));
  }

  setBlogHeader(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/cms-admin/useful_media/blog_description/`, data).pipe(retry(2));
  }

  putBlogHeader(data): Observable<any> {
    return this.http.put(`${environment.apiUrl}/cms-admin/useful_media/blog_description/${data.id}/`, data).pipe(retry(2));
  }

  getSpecificBlogHeader(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/cms-admin/useful_media/specific_blog_description/`).pipe(retry(2));
  }

  setSpecificBlogHeader(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/cms-admin/useful_media/specific_blog_description/`, data).pipe(retry(2));
  }

  putSpecificBlogHeader(data): Observable<any> {
    return this.http.put(`${environment.apiUrl}/cms-admin/useful_media/specific_blog_description/${data.id}/`, data).pipe(retry(2));
  }

  getAutherBlogHeader(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/cms-admin/useful_media/author_description/`).pipe(retry(2));
  }

  setAutherBlogHeader(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/cms-admin/useful_media/author_description/`, data).pipe(retry(2));
  }

  putAutherBlogHeader(data): Observable<any> {
    return this.http.put(`${environment.apiUrl}/cms-admin/useful_media/author_description/${data.id}/`, data).pipe(retry(2));
  }
  //#endregion :- blog


  // #region :- News

  getNewsAuthor(mediaType: number): Observable<any> {
    return this.http.get(`${environment.apiUrl}/cms-admin/useful_media/author/?media_type_id=${mediaType}`).pipe(retry(2));
  }

  setNewsAuthor(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/cms-admin/useful_media/author/`, data).pipe(retry(2));
  }

  getNewsUsefulMedia(mediaType: number): Observable<any> {
    return this.http.get(`${environment.apiUrl}/cms-admin/useful_media/useful-media/?media_type_id=${mediaType}`).pipe(retry(2));
  }

  setNewsUsefulMedia(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/cms-admin/useful_media/useful-media/`, data);
  }

  getNewsSpecificMedia(mediaType: number, pagination: any): Observable<any> {
    // return this.http.get(`${environment.apiUrl}/cms-admin/useful_media/useful-media-additional/?media_type_id=${mediaType}`).pipe(retry(2));
    //COMMENT :- THE URL NAME USING 'BLOG' FOR NEWS FETCH BECAUSE 'MEDIATYPE' DECIDE WHAT NEWS OR BLOG WANT.
    return this.http.get(`${environment.apiUrl}/cms-admin/useful_media/specific_blog_additional/?media_type_id=${mediaType}&limit=${pagination.limit}&offset=${pagination.current}`).pipe(retry(2));
  }

  setNewsSpecificMedia(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/cms-admin/useful_media/useful-media-additional/`, data).pipe(retry(2));
  }

  getNewsHeader(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/cms-admin/useful_media/news_description/`).pipe(retry(2));
  }

  setNewsHeader(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/cms-admin/useful_media/news_description/`, data).pipe(retry(2));
  }

  putNewsHeader(data): Observable<any> {
    return this.http.put(`${environment.apiUrl}/cms-admin/useful_media/news_description/${data.id}/`, data).pipe(retry(2));
  }


  getSpecificNewsHeader(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/cms-admin/useful_media/specific_news_description/`).pipe(retry(2));
  }

  setSpecificNewsHeader(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/cms-admin/useful_media/specific_news_description/`, data).pipe(retry(2));
  }

  putSpecificNewsHeader(data): Observable<any> {
    return this.http.put(`${environment.apiUrl}/cms-admin/useful_media/specific_news_description/${data.id}/`, data).pipe(retry(2));
  }

  getAutherNewsHeader(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/cms-admin/useful_media/author_description/`).pipe(retry(2));
  }

  setAutherNewsHeader(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/cms-admin/useful_media/author_description/`, data).pipe(retry(2));
  }

  putAutherNewsHeader(data): Observable<any> {
    return this.http.put(`${environment.apiUrl}/cms-admin/useful_media/author_description/${data.id}/`, data).pipe(retry(2));
  }

  // #endregion :- News

  getSeoDescription(id: number) {
    return this.http.get(`${environment.apiUrl}/cms-admin/useful_media/useful-media/${id}/`)
  }

  displayBlogOnHome(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/cms-admin/useful_media/specific_blog_additional/update-show-home-blog/`, data).pipe(retry(2));
  }


}
