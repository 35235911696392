<div class="container">
    <form [formGroup]="headerForm" (ngSubmit)="onSubmit(headerForm)">
        <div class="row">
            <div class="col-md-12 mt-2" formArrayName="headers" *ngFor="let item of headers().controls; let i = index;">
                <div class="border shadow bg-white p-4 mt-2" [formGroupName]="i">
                    <a *ngIf="headers().controls.length>1" href="javascript:void(0)" class="ms-2 close-btn"
                        (click)="removeheaders(i)"><i class="uil-times fa-2x"></i></a>
                    <div class="my-2 row">
                        <div class="col-md-6">
                            <label class="col-form-label">Heading</label>
                            <input type="text" class="form-control" placeholder="Heading" formControlName="heading"
                                (change)="dataChanged(i)"
                                [ngClass]="{'invalid': item.get('heading').invalid && isSubmitted}">
                        </div>
                        <div class="col-md-6">
                            <label class="col-form-label">Sub Heading</label>
                            <input type="text" class="form-control" placeholder="Sub Heading"
                                formControlName="sub_heading" (change)="dataChanged(i)"
                                [ngClass]="{'invalid': item.get('sub_heading').invalid && isSubmitted}">
                        </div>
                    </div>
                    <div class="my-2 row">
                        <div class="col-md-6">
                            <label class="col-form-label">Banner Image URL</label>
                            <div class="d-flex">
                                <input type="text" class="form-control" placeholder="URL"
                                    formControlName="home_banner_image_url" (change)="dataChanged(i)">
                                <a [href]="headerForm.value.headers[i].home_banner_image_url" target="_blank"><img
                                        class="ms-1" height="40"
                                        [src]="headerForm.value.headers[i].home_banner_image_url"></a>
                                <button class="btn btn-primary ms-1" type="button"
                                    (click)="openUploadModal(i, 'home_banner_image_url')"><i
                                        class="fas fa-cloud-upload-alt"></i></button>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <label class="col-form-label">Banner Image URL for Mobile</label>
                            <div class=" d-flex">
                                <input type="text" class="form-control" placeholder="URL"
                                    formControlName="home_mob_banner_image_url" (change)="dataChanged(i)">
                                <a [href]="headerForm.value.headers[i].home_mob_banner_image_url" target="_blank"><img
                                        class="ms-1" height="40"
                                        [src]="headerForm.value.headers[i].home_mob_banner_image_url"></a>
                                <button class="btn btn-primary ms-1" type="button"
                                    (click)="openUploadModal(i, 'home_mob_banner_image_url')"><i
                                        class="fas fa-cloud-upload-alt"></i></button>
                            </div>
                        </div>
                    </div>
                    <div class="my-2 row">
                        <div class="col-md-6">
                            <label class="col-form-label">Center Image URL</label>
                            <div class=" d-flex">
                                <input type="text" class="form-control" placeholder="URL"
                                    formControlName="banner_image_url" (change)="dataChanged(i)">
                                <a [href]="headerForm.value.headers[i].banner_image_url" target="_blank"><img
                                        class="ms-1" height="40"
                                        [src]="headerForm.value.headers[i].banner_image_url"></a>
                                <button class="btn btn-primary ms-1" type="button"
                                    (click)="openUploadModal(i, 'banner_image_url')"><i
                                        class="fas fa-cloud-upload-alt"></i></button>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <label class="col-form-label">Center Image URL for Mobile</label>
                            <div class=" d-flex">
                                <input type="text" class="form-control" placeholder="URL"
                                    formControlName="mob_banner_image_url" (change)="dataChanged(i)">
                                <a [href]="headerForm.value.headers[i].mob_banner_image_url" target="_blank"><img
                                        class="ms-1" height="40"
                                        [src]="headerForm.value.headers[i].mob_banner_image_url"></a>
                                <button class="btn btn-primary ms-1" type="button"
                                    (click)="openUploadModal(i, 'mob_banner_image_url')"><i
                                        class="fas fa-cloud-upload-alt"></i></button>
                            </div>
                        </div>
                    </div>
                    <div class="my-2 row">
                        <div class="col-md-4">
                            <label class="col-form-label">Banner Button URL</label>
                            <div class="d-flex">
                                <input type="text" (change)="dataChanged(i)" class="form-control"
                                    placeholder="Banner Url" formControlName="home_banner_button_url">
                            </div>
                        </div>

                        <div class="col-md-4">
                            <label class="col-form-label">Count on Us Button URL</label>
                            <div class="d-flex">
                                <input type="text" (change)="dataChanged(i)" class="form-control"
                                    placeholder="Button Url" formControlName="count_on_us_url">
                            </div>
                        </div>

                        <div class="col-md-4">
                            <label class="col-form-label">FAQ Button URL</label>
                            <div class="d-flex">
                                <input type="text" (change)="dataChanged(i)" class="form-control"
                                    placeholder="Button Url" formControlName="faq_url">
                            </div>
                        </div>

                        <div class="my-2 row">
                            <div class="col-md-4">
                                <label class="col-form-label">Testimonial Rating</label>
                                <div class="d-flex">
                                    <input type="number" min="1" max="5" (change)="dataChanged(i)" class="form-control"
                                        placeholder="Button Url" formControlName="testimonial_rating">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12 d-flex p-5">
                <div class="m-auto d-flex">
                    <button *ngIf="(page=='home' && checkPermission('content_management_home_create'))  && 
                      (page=='home' && checkPermission('content_management_home_edit'))" class="btn btn-info me-2"
                        type="submit"><i
                            [ngClass]="{'fa fa-pulse fa-spin fa-spinner': isUpdating,'bx bx-save': !isUpdating}"></i>
                        Save</button>

                    <button *ngIf="(page=='b2b-home')" class="btn btn-info me-2" type="submit"><i
                            [ngClass]="{'fa fa-pulse fa-spin fa-spinner': isUpdating,'bx bx-save': !isUpdating}"></i>
                        Save</button>

                    <app-publish-btn *ngIf="(page=='home' && checkPermission('content_management_home_publish')) || 
                  (page=='consultant' && checkPermission('content_management_consultant_publish'))" class="p-0"
                        [publish]="'TrustedPartner'" [page]="page"></app-publish-btn>
                </div>
            </div>
        </div>
    </form>
</div>