<div class="container-fluid">
    <!-- start page title -->
    <app-page-title title="Seo Page" [breadcrumbItems]="breadCrumbItems"></app-page-title>

    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <!-- orientation="vertical"  -->
                    <ul ngbNav #justifiednavpills="ngbNav" [activeId]="1" class="nav-pills nav-justified bg-light">
                        <li [ngbNavItem]="1">
                            <a ngbNavLink>
                                <span>Home&nbsp;Page</span>
                            </a>
                            <ng-template ngbNavContent>
                                <app-seo-section [page]="'home'"></app-seo-section>
                            </ng-template>
                        </li>
                        <li [ngbNavItem]="2">
                            <a ngbNavLink>
                                <span>Visa&nbsp;Home&nbsp;Page</span>
                            </a>
                            <ng-template ngbNavContent>
                                <app-seo-section [page]="'visa_home'"></app-seo-section>
                            </ng-template>
                        </li>
                        <li [ngbNavItem]="3">
                            <a ngbNavLink>
                                <span>Country</span>
                            </a>
                            <ng-template ngbNavContent>
                                <div class="my-3 row">
                                    <div class="offset-md-8 col-md-4">
                                        <ng-select [(items)]="countryList" [multiple]="false"
                                            [placeholder]="'Select Country'" bindLabel="name" bindValue="id"
                                            [(ngModel)]="country" [clearable]="false"
                                            (change)="selectCountry(country)"></ng-select>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-6 offset-lg-3 set-minwh-1" *ngIf="country==null;">
                                        <div class="card text-center p-4">
                                            Select Country to Continue
                                        </div>
                                    </div>
                                    <div class="col-12" *ngIf="country">
                                        <app-seo-section [country]="country" [page]="'country'"></app-seo-section>
                                    </div>
                                </div>
                            </ng-template>
                        </li>
                        <li [ngbNavItem]="4">
                            <a ngbNavLink>
                                <span>Category</span>
                            </a>
                            <ng-template ngbNavContent>
                                <div class="my-3 row">
                                    <div class="offset-md-4 col-md-4">
                                        <ng-select [(items)]="countryList" [multiple]="false"
                                            [placeholder]="'Select Country'" bindLabel="name" bindValue="id"
                                            [(ngModel)]="country" [clearable]="false"
                                            (change)="selectCountry(country)"></ng-select>
                                    </div>
                                    <div class="col-md-4">
                                        <ng-select [(items)]="categoryList" [multiple]="false"
                                            [placeholder]="'Select Category'" bindLabel="name" bindValue="id"
                                            [(ngModel)]="category" [clearable]="false"
                                            (change)="selectCategory(category)"></ng-select>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-8 offset-lg-2 set-minwh-1"
                                        *ngIf="country==null || category==null;">
                                        <div class="card text-center p-4">
                                            Select {{country ? '' : 'Country &'}} {{category ? '': 'Category'}} to
                                            Continue
                                        </div>
                                    </div>
                                    <div class="col-12" *ngIf="country && category">
                                        <app-seo-section [page]="'category'"></app-seo-section>
                                    </div>
                                </div>
                            </ng-template>
                        </li>
                        <li [ngbNavItem]="5">
                            <a ngbNavLink>
                                <span>Product</span>
                            </a>
                            <ng-template ngbNavContent>
                                <div class="my-3 row">
                                    <div class="col-md-4">
                                        <ng-select [(items)]="countryList" [multiple]="false"
                                            [placeholder]="'Select Country'" bindLabel="name" bindValue="id"
                                            [(ngModel)]="country" [clearable]="false"
                                            (change)="selectCountry(country)"></ng-select>
                                    </div>
                                    <div class="col-md-4">
                                        <ng-select [(items)]="categoryList" [multiple]="false"
                                            [placeholder]="'Select Category'" bindLabel="name" bindValue="id"
                                            [(ngModel)]="category" [clearable]="false"
                                            (change)="selectCategory(category)"></ng-select>
                                    </div>
                                    <div class="col-md-4">
                                        <ng-select [(items)]="productList" [multiple]="false"
                                            [placeholder]="'Select Product'" bindLabel="name" bindValue="id"
                                            [(ngModel)]="product" [clearable]="false"
                                            (change)="selectProduct(product)"></ng-select>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-6 offset-lg-3 set-minwh-1"
                                        *ngIf="country==null || category==null || product==null;">
                                        <div class="card text-center p-4">
                                            Select Country to Continue
                                        </div>
                                    </div>
                                    <div class="col-12" *ngIf="country && category && product">
                                        <app-seo-section [country]="country" [page]="'product'"></app-seo-section>
                                    </div>
                                </div>
                            </ng-template>
                        </li>
                        <li [ngbNavItem]="6">
                            <a ngbNavLink>
                                <span>Consultant&nbsp;Page</span>
                            </a>
                            <ng-template ngbNavContent>
                                <app-seo-section [page]="'consultant_page'"></app-seo-section>
                            </ng-template>
                        </li>
                        <li [ngbNavItem]="7">
                            <a ngbNavLink>
                                <span>DIY&nbsp;Assistance</span>
                            </a>
                            <ng-template ngbNavContent>
                                <app-seo-section [page]="'diy_assistance'"></app-seo-section>
                            </ng-template>
                        </li>
                        <li [ngbNavItem]="8">
                            <a ngbNavLink>
                                <span>About&nbsp;Us</span>
                            </a>
                            <ng-template ngbNavContent>
                                <app-seo-section [page]="'about_us'"></app-seo-section>
                            </ng-template>
                        </li>
                        <li [ngbNavItem]="9">
                            <a ngbNavLink>
                                <span>Testimonials</span>
                            </a>
                            <ng-template ngbNavContent>
                                <app-seo-section [page]="'testimonials'"></app-seo-section>
                            </ng-template>
                        </li>
                        <li [ngbNavItem]="10">
                            <a ngbNavLink>
                                <span>Faq</span>
                            </a>
                            <ng-template ngbNavContent>
                                <app-seo-section [page]="'faq'"></app-seo-section>
                            </ng-template>
                        </li>
                        <li [ngbNavItem]="11">
                            <a ngbNavLink>
                                <span>Faq&nbsp;Country</span>
                            </a>
                            <ng-template ngbNavContent>
                                <div class="my-3 row">
                                    <div class="offset-md-8 col-md-4">
                                        <ng-select [(items)]="countryList" [multiple]="false"
                                            [placeholder]="'Select Country'" bindLabel="name" bindValue="id"
                                            [(ngModel)]="country" [clearable]="false"
                                            (change)="selectCountry(country);"></ng-select>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-6 offset-lg-3 set-minwh-1" *ngIf="country==null;">
                                        <div class="card text-center p-4">
                                            Select Country to Continue
                                        </div>
                                    </div>
                                    <div class="col-12" *ngIf="country">
                                        <app-seo-section [country]="country" [page]="'faq_country'"></app-seo-section>
                                    </div>
                                </div>
                            </ng-template>
                        </li>
                        <li [ngbNavItem]="12">
                            <a ngbNavLink>
                                <span>Sitemap</span>
                            </a>
                            <ng-template ngbNavContent>
                                <app-sitemap></app-sitemap>

                            </ng-template>
                        </li>
                        <li [ngbNavItem]="13">
                            <a ngbNavLink>
                                <span>Business Home Page</span>
                            </a>
                            <ng-template ngbNavContent>
                                <app-seo-section [page]="'business_home'"></app-seo-section>
                            </ng-template>
                        </li>
                    </ul>
                    <div [ngbNavOutlet]="justifiednavpills"></div>
                </div>
            </div>
        </div>
    </div>
</div>