import { Injectable } from "@angular/core";
import { retry } from "rxjs/operators";
import { Observable, Subject } from "rxjs";
import { environment } from "src/environments/environment";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import {
  dataToSend,
  fiter,
  searchItems,
} from "../models/visa-management.model";

@Injectable({
  providedIn: "root",
})
export class VisaManageService {
  constructor(private http: HttpClient) { }
  public searchSubject = new Subject<any>();

  // getIntegrationsList(): Observable<any>  {
  //   return this.http.get(`${environment.apiUrl}/integration/`).pipe(retry(2));
  // }

  // setIntegrationsList(data): Observable<any> {
  //   return this.http.post(`${environment.apiUrl}/integration/`, data)
  // }

  // updateIntegrationsList(id,data): Observable<any> {
  //   return this.http.put(`${environment.apiUrl}/integration/${id}/`, data)
  // }

  checkStatus(pax: any) {
    return this.http.get(
      `${environment.apiUrl}/consulate-form/view-form-filling/?pax_id=${pax}`
    );
  }

  customerVisaProcessDetails(limit, offset, searchFields: searchItems) {
    return this.http
      .get(
        `${environment.apiUrl}/customer-visaprocess-details/` +
        `?status=${searchFields?.status}` +
        `&limit=${limit}` +
        `&offset=${offset}` +
        `${searchFields?.name ? "&name=" : ""}${searchFields?.name ? searchFields.name : ""
        }` +
        `${searchFields?.b2b ? "&b2b=" : ""}${searchFields?.b2b ? searchFields.b2b : ""
        }` +
        `${searchFields?.diy ? "&diy=" : ""}${searchFields?.diy ? searchFields.diy : ""
        }` +
        `${searchFields?.country ? "&country=" : ""}${searchFields?.country ? searchFields?.country : ""
        }` +
        `${searchFields?.search ? "&" + searchFields?.dropDownItem : ""}${searchFields?.search ? "=" + searchFields?.search : ""
        }` +
        `${searchFields?.choice ? "&choice=" : ""}${searchFields?.choice}` +
        `${searchFields?.sort ? "&sort=" : ""}${searchFields?.sort}`
      )
      .pipe(retry(2));
  }

  paxVisaprocess(application_id) {
    return this.http.get(
      `${environment.apiUrl}/pax-visaprocess/?visa_process=${application_id}`
    );
  }

  paxWorkFlow(id) {
    return this.http.get(`${environment.apiUrl}/pax-workflow/?pax=${id}`);
  }

  getCountryQuestionsByPax(id) {
    return this.http.get(
      `${environment.apiUrl}/country-questions/get-country-questions/?pax_id=${id}`
    );
  }

  uploadDetailsUpdate(api, data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/${api}/`, data);
  }

  getUploadDetails(api, pax_id): Observable<any> {
    return this.http.get(`${environment.apiUrl}/${api}/?pax_id=${pax_id}`);
  }

  getVerifyDoc(api, pax_id, doc_type?, role?) {
    return this.http
      .get(
        `${environment.apiUrl}/${api}/?pax_id=${pax_id}` +
        `${role ? "&role=" : ""}${role ? role : ""}` +
        `${doc_type ? "&doc_type=" : ""}${doc_type ? doc_type : ""}`
      )
      .pipe(retry(2));
  }

  getDetailsUpdate(api, data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/${api}/`, data);
  }

  confirmInVisaFlow(api, data) {
    return this.http.post(`${environment.apiUrl}/${api}/`, data);
  }

  verifyDocument(api, data) {
    return this.http.put(`${environment.apiUrl}/${api}/`, data);
  }

  dataUpdate(api, data) {
    return this.http.post(`${environment.apiUrl}/${api}/`, data);
  }

  dataUpdatePut(api, data) {
    return this.http.put(`${environment.apiUrl}/${api}/`, data);
  }

  unblockCountryQuestions(data, pax_id) {
    return this.http.post(
      `${environment.apiUrl}/country-questions/unblock-country-questions/?pax_id=${pax_id}`,
      data
    );
  }

  agentVerificationSuccess(data) {
    return this.http.post(
      `${environment.apiUrl}/master-form/agent-verification-success/`,
      data
    );
  }

  uploadImgFile(data: any): Observable<any> {
    // const headers = new HttpHeaders({ 'Content-Type': 'multipart/form-data' });, { headers }
    return this.http.post(
      `${environment.apiUrl}/cms-admin/common/file-upload/`,
      data,
      {
        reportProgress: true,
        observe: "events",
      }
    );
  }

  uploadSignzyProcess(data: any): Observable<any> {
    let token: any = localStorage.getItem("token");
    const headers = new HttpHeaders({
      Authorization: `Token ${JSON.parse(token)}`,
    });
    return this.http
      .post(`${environment.apiUrl}/signzy/`, data, { headers: headers })
      .pipe(retry(2));
  }

  postVisaProcessSteps(data: any): Observable<any> {
    return this.http.post(
      `${environment.apiUrl}/customer-data-collection/`,
      data
    );
  }

  getCoverNotification(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/cover-letter/`).pipe(retry(2));
  }

  getNotificationsList(status_id, workflow_process_id) {
    return this.http
      .get(
        `${environment.apiUrl}/visaprocess-notification/?status_id=${status_id}&workflow_process_id=${workflow_process_id}`
      )
      .pipe(retry(2));
  }

  getVisaDetails(id) {
    return this.http
      .get(`${environment.apiUrl}/my-visa/?visa_process_id=${id}`)
      .pipe(retry(2));
  }

  getConsulateData(pax_id) {
    return this.http
      .get(
        `${environment.apiUrl}/consulate-form/view-form-filling/?pax_id=${pax_id}`
      )
      .pipe(retry(2));
  }

  getDocument(pax_id) {
    return this.http
      .get(`${environment.apiUrl}/documents-upload/?pax_id=${pax_id}`)
      .pipe(retry(2));
  }

  getPassport(pax_id) {
    return this.http
      .get(`${environment.apiUrl}/signzy-user-details/?pax_id=${pax_id}`)
      .pipe(retry(2));
  }

  getDetails(pax_id) {
    return this.http
      .get(
        `${environment.apiUrl}/data-update/get-update-data/?pax_id=${pax_id}`
      )
      .pipe(retry(2));
  }

  getShedule(pax_id) {
    return this.http
      .get(`${environment.apiUrl}/user-schedule/?pax_id=${pax_id}`)
      .pipe(retry(2));
  }

  getOtherDocs(pax_id) {
    return this.http
      .get(
        `${environment.apiUrl}/country-questions/get-other-documents/?pax_id=${pax_id}`
      )
      .pipe(retry(2));
  }

  getSavedVisaImg(paxId: any) {
    let token: any = localStorage.getItem("token");
    const headers = new HttpHeaders({
      Authorization: `Token ${JSON.parse(token)}`,
    });
    return this.http
      .get(`${environment.apiUrl}/signzy-user-details/?pax_id=${paxId}`, {
        headers: headers,
      })
      .pipe(retry(2));
  }

  getCoverLetterConversion(pax_id, cover_letter) {
    return this.http
      .get(
        `${environment.apiUrl}/cover-letter/cover-letter-conversion/?pax_id=${pax_id}&cover_letter=${cover_letter}`
      )
      .pipe(retry(2));
  }

  getCategoiesOnCountry(countryId?: Array<number>): Observable<any> {
    let params = new HttpParams();
    params = params.append("country_ids", countryId ? countryId.join(",") : "");
    return this.http.get<any>(`${environment.apiUrl}/category-country/`, {
      params: params,
    });
  }

  getVisaFilterResult(data: dataToSend): Observable<fiter> {
    let params = new HttpParams();
    params = params.append("choice", data?.choice);
    params = params.append(
      "country_ids",
      data.countryIds ? data.countryIds.join(",") : ""
    );
    if (!data?.countryIds)
      params = params.append(
        "category_names",
        data.categoryIds ? data.categoryIds.join(",") : ""
      );
    if (data?.countryIds)
      params = params.append(
        "category_ids",
        data.categoryIds ? data.categoryIds.join(",") : ""
      );

    params = params.append("express", data?.express);
    // if (data?.diy === 2) params = params.append("diy", data?.diy);
    // if (data?.visa === 1) params = params.append("visa", data?.visa);

    if (data?.fromDate) {
      params = params.append("date_from", data?.fromDate);
    }
    if (data?.toDate) {
      params = params.append("date_to", data?.toDate);
    }
    if (data?.b2b) {
      params = params.append("b2b", data?.b2b);
    }
    if (data?.application_from_date) {
      params = params.append(
        "date_of_application_from",
        data?.application_from_date
      );
    }
    if (data?.application_to_date) {
      params = params.append(
        "date_of_application_to",
        data?.application_to_date
      );
    }
    if (data?.limit) {

      params = params.append("limit", data?.limit);
      params = params.append("offset", data?.offset);
    }
    if (data?.expertIds) {
      params = params.append("expert", data.expertIds);
    }
    params = params.append("product_status", data?.product_status);
    params = params.append("status", data?.status);
    params = params.append("sort", "last");
    // params = params.append("sort", data?.sort ? data.sort : "first");
    params = params.append("source", data?.source);
    return this.http.get<fiter>(
      `${environment.apiUrl}/customer-visaprocess-details-filter/`,
      { params: params }
    );
  }

  downloadCSVFile(queryParams: any, url: string): Observable<Blob> {
    let params = new HttpParams();
    params = params.append("choice", queryParams?.choice);
    params = params.append("b2b", queryParams?.b2b || false);
    if (queryParams.filteredOrNot) {
      params = params.append(
        "product_status",
        queryParams.filteredData?.product_status
      );
      params = params.append("status", queryParams.filteredData?.status);
      params = params.append(
        "country_ids",
        queryParams.filteredData.countryIds
          ? queryParams.filteredData.countryIds.join(",")
          : ""
      );

      if (!queryParams.filteredData?.countryIds)
        params = params.append(
          "category_names",
          queryParams.filteredData.categoryIds
            ? queryParams.filteredData.categoryIds.join(",")
            : ""
        );
      if (queryParams?.filteredData?.countryIds)
        params = params.append(
          "category_ids",
          queryParams.filteredData.categoryIds
            ? queryParams.filteredData.categoryIds.join(",")
            : ""
        );

      params = params.append("express", queryParams?.filteredData?.express);
      //   if (queryParams?.filteredData?.diy === 2)
      //   params = params.append("diy", queryParams?.filteredData?.diy);
      // if (queryParams?.filteredData?.visa === 1)
      //   params = params.append("visa", queryParams?.filteredData?.visa);
      if (
        queryParams?.filteredData?.fromDate &&
        queryParams?.filteredData?.toDate
      ) {
        params = params.append(
          "date_from",
          queryParams?.filteredData?.fromDate
        );
        params = params.append("date_to", queryParams?.filteredData?.toDate);
      }
      if (
        queryParams?.filteredData?.application_from_date &&
        queryParams?.filteredData?.application_to_date
      ) {
        params = params.append(
          "date_of_application_from",
          queryParams?.filteredData?.application_from_date
        );
        params = params.append(
          "date_of_application_to",
          queryParams?.filteredData?.application_to_date
        );
      }
    } else {
      params = params.append(
        `${queryParams.serachKey}`,
        `${queryParams.searchText}`
      );
      params = params.append("status", queryParams?.status);
    }
    return this.http.get(
      `${environment.apiUrl}${url}`,
      { params: params, responseType: "blob" }
    );
  }

  editFields(data: any, type: string) {
    const id = data.tableData.id;
    let value: any;
    if (type === "Inline") {
      value = {
        remarks: data.value,
      };
    } else if (type === "Visa_Status") {
      value = {
        visa_status: data.value,
      };
    } else if (type === "expert_edit") {
      value = {
        experts: data.value,
      };
    }

    return this.http.patch(
      `${environment.apiUrl}/update-customer-visaprocess-details/${id}/`,
      value
    );
  }

  postVisaProcessData(data: any) {
    return this.http.post(`${environment.apiUrl}/visapro-pax-creation/`, data);
  }

  acceptUser(paxId: any) {
    return this.http.post(
      `${environment.apiUrl}/consulate-form/user-verification-success/?pax_id=${paxId}`,
      {}
    );
  }

  rejectUser(paxId: any) {
    return this.http.post(
      `${environment.apiUrl}/consulate-form/user-verification-failure/?pax_id=${paxId}`,
      {}
    );
  }

  getProfileDetails(id: any) {
    return this.http.get(
      `${environment.apiUrl}/users/my-profile-visapro/?my_profile_cus_id=${id}`
    );
  }

  getSpecificProfileData(id: any) {
    return this.http.get(
      `${environment.apiUrl}/users/my-profile-visapro/get-my-profile/?my_profile_id=${id}`
    );
  }

  validatePassport(data) {
    return this.http.post(
      `${environment.apiUrl}/users/my-profile-visapro/validate-passport-visapro/`,
      data
    );
  }
  downloadCordeliaCsv() {
    return this.http.get(`${environment.apiUrl}/cordelia-csv/`, {
      responseType: "blob",
    });
  }
}
