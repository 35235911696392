<div class="container-fluid set-minwh">
  <div class="my-3 row">
    <div class="offset-md-8 col-md-4">
      <ng-select [(items)]="countryList" [multiple]="false" [placeholder]="'Select Country'" bindLabel="name"
        bindValue="id" [(ngModel)]="country" [clearable]="false" (change)="getAddOnForm()"></ng-select>
    </div>
  </div>

  <div class="container d-flex align-center justify-content-center text-warning mt-3" *ngIf="!country">
    Select a Country
  </div>

  <div class="container" *ngIf="country">
    <form class="row" [formGroup]="addOnForm" (ngSubmit)="submitAddOn()">
      <div class="offset-md-2 col-md-8 mt-2">
        <div class="border shadow p-4 mt-2 bg-white">
          <!-- Heading -->
          <div class="my-3 row">
            <div class="col-md-6">
              <label class="col-form-label mt-1">Heading</label>
              <input type="text" class="form-control" placeholder="Heading" formControlName="heading" [ngClass]="{
              'is-invalid': addOnForm.get('heading')?.invalid && addOnForm.get('heading')?.touched
            }" />
              <div *ngIf="addOnForm.get('heading')?.invalid && addOnForm.get('heading')?.touched"
                class="invalid-feedback">
                Heading is required.
              </div>
            </div>

            <!-- Price Heading -->
            <div class="col-md-6">
              <label class="col-form-label mt-1">Price Heading</label>
              <input type="text" class="form-control" placeholder="Price Heading" formControlName="price_heading"
                [ngClass]="{
              'is-invalid': addOnForm.get('price_heading')?.invalid && addOnForm.get('price_heading')?.touched
            }" />
              <div *ngIf="addOnForm.get('price_heading')?.invalid && addOnForm.get('price_heading')?.touched"
                class="invalid-feedback">
                Price Heading is required.
              </div>
            </div>

            <!-- Logo -->
            <div class="col-md-12">
              <label class="col-form-label mt-1">Logo</label>
              <div class="d-flex">
                <input type="text" class="form-control" placeholder="URL" formControlName="logo">
                <div class="d-flex">
                  <a [href]="addOnForm.value.logo" target="_blank"><img class="ms-1" height="40"
                      [src]="addOnForm.value.logo"></a>
                  <button class="btn btn-primary ms-1" type="button" (click)="openUploadModal('logo')"><i
                      class="fas fa-cloud-upload-alt"></i></button>
                </div>
              </div>
              <div *ngIf="addOnForm.get('logo')?.invalid && addOnForm.get('logo')?.touched" class="invalid-feedback">
                Logo URL is required.
              </div>
            </div>

            <!-- Info -->
            <div class="col-md-12">
              <label class="col-form-label mt-1">Info</label>
              <div formArrayName="info" class="p-1 border">
                <div *ngFor="let infoControl of info.controls; let i = index" [formGroupName]="i" class="mb-1">
                  <div class="d-flex align-items-center">
                    <!-- Input for 'text' -->
                    <div class="w-50 me-2">
                      <label class="col-form-label mt-1">Text</label>
                      <input type="text" class="form-control" (change)="dataChanged(i)" formControlName="text"
                        placeholder="Enter text"
                        [ngClass]="{ 'is-invalid': infoControl.get('text')?.invalid && infoControl.get('text')?.touched }" />
                      <div *ngIf="infoControl.get('text')?.invalid && infoControl.get('text')?.touched"
                        class="invalid-feedback">
                        Text is required.
                      </div>
                    </div>

                    <!-- Input for 'icon' -->
                    <div class="w-50">
                      <label class="col-form-label mt-1">Icon</label>
                      <div class="d-flex">
                        <input type="text" class="form-control" formControlName="icon" placeholder="Enter icon"
                          [ngClass]="{ 'is-invalid': infoControl.get('icon')?.invalid && infoControl.get('icon')?.touched }" />
                        <a [href]="addOnForm.value.info[i].icon" target="_blank">
                          <img class="ms-1" height="40" [src]="addOnForm.value.info[i].icon">
                        </a>
                        <button class="btn btn-primary ms-1" type="button" (click)="openUploadModal('icon',i)"><i
                            class="fas fa-cloud-upload-alt"></i></button>
                      </div>
                      <div *ngIf="infoControl.get('icon')?.invalid && infoControl.get('icon')?.touched"
                        class="invalid-feedback">
                        Icon is required.
                      </div>
                    </div>

                    <!-- Remove Button -->
                    <button type="button" class="btn btn-sm btn-danger ms-2" (click)="removeInfo(i)">
                      <i class="fa fa-times"></i>
                    </button>
                  </div>
                </div>
                <!-- Add Info Button -->
                <button type="button" class="btn btn-sm btn-primary mt-2" (click)="addInfo()">
                  <i class="fa fa-plus"></i> Add Info
                </button>
              </div>
            </div>


            <!-- Benefits -->
            <div class="col-md-12">
              <label class="col-form-label mt-1">Benefits</label>
              <div formArrayName="benefits" class="benefits">
                <div *ngFor="let benefitControl of benefits.controls; let i = index"
                  class="mb-1 d-flex align-items-center">
                  <!-- <input type="text" class="form-control" [formControlName]="i" placeholder="Enter benefit" [ngClass]="{
                  'is-invalid': benefitControl.invalid && benefitControl.touched
                }" /> -->
                  <ckeditor class="w-100 mb-2" [config]="editorConfig" [editor]="Editor" formControlName="{{i}}"
                    [ngClass]="{ 'is-invalid': benefitControl.invalid && benefitControl.touched }"
                    data="<p>Enter benefit</p>">
                  </ckeditor>
                  <button type="button" class="btn btn-sm btn-danger ms-2" (click)="removeBenefit(i)">
                    <i class="fa fa-times"></i>
                  </button>
                  <div *ngIf="benefitControl.invalid && benefitControl.touched" class="invalid-feedback">
                    Benefit is required.
                  </div>
                </div>
                <button type="button" class="btn btn-sm btn-primary mt-2" (click)="addBenefit()">
                  <i class="fa fa-plus"></i> Add Benefit
                </button>
              </div>
            </div>

            <!-- Snackbar -->
            <div class="col-md-12">
              <label class="col-form-label mt-1">Snackbar</label>
              <input type="text" class="form-control" placeholder="Snackbar" formControlName="snackbar" [ngClass]="{
              'is-invalid': addOnForm.get('snackbar')?.invalid && addOnForm.get('snackbar')?.touched
            }" />
              <div *ngIf="addOnForm.get('snackbar')?.invalid && addOnForm.get('snackbar')?.touched"
                class="invalid-feedback">
                Snackbar is required.
              </div>
            </div>

            <!-- Insurance -->
            <div class="col-md-6">
              <label class="col-form-label mt-1">Insurance</label>
              <input type="number" class="form-control" placeholder="Insurance" formControlName="insurance" [ngClass]="{
              'is-invalid': addOnForm.get('insurance')?.invalid && addOnForm.get('insurance')?.touched
            }" />
              <div *ngIf="addOnForm.get('insurance')?.invalid && addOnForm.get('insurance')?.touched"
                class="invalid-feedback">
                Insurance is required.
              </div>
            </div>

            <!-- Insurance_71_80 -->
            <div class="col-md-6">
              <label class="col-form-label mt-1">Insurance (71-80)</label>
              <input type="number" class="form-control" placeholder="Insurance_71_80" formControlName="insurance_71_80"
                [ngClass]="{
              'is-invalid': addOnForm.get('insurance_71_80')?.invalid && addOnForm.get('insurance_71_80')?.touched
            }" />
              <div *ngIf="addOnForm.get('insurance_71_80')?.invalid && addOnForm.get('insurance_71_80')?.touched"
                class="invalid-feedback">
                Insurance 71-80 is required.
              </div>
            </div>

            <!-- Submit -->
            <div class="my-3 text-end">
              <button class="btn btn-success" type="submit">
                <i class="fa fa-save"></i> Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>


  </div>