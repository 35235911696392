import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import { CountryManagementService } from 'src/app/core/services/country-management.service';
import { ToastrService } from 'ngx-toastr';
import { SharedService } from 'src/app/core/services/shared.service';
import { V3ServicesService } from 'src/app/core/services/v3-services.service';
import { PermissionService } from 'src/app/core/services/permision.service';
import * as ClassicEditor from "@ckeditor/ckeditor5-build-classic";

@Component({
  selector: 'app-about-visa',
  templateUrl: './about-visa.component.html',
  styleUrls: ['./about-visa.component.scss']
})
export class AboutVisaComponent {


  public Editor = ClassicEditor;
  aboutForm: FormGroup;
  hasChanges = false;

  isUpdating = false;
  deletedabout = [];

  @Input() country;
  @Input() page;
  category_id = this.sharedService.selectedCmsCategoryId;
  product_id = this.sharedService.selectedCmsProductId;
  publishPage: string;

  constructor(private fb: FormBuilder,
    private toastr: ToastrService,
    private sharedService: SharedService,
    public permission: PermissionService,
    private api: V3ServicesService) { }

  ngOnInit(): void {
    this.resetForm();
    this.getAbout();
  }

  resetForm() {
    this.aboutForm = this.fb.group({
      about: this.fb.array([
        this.fb.group({
          heading: ['', [Validators.required]],
          description_datas: new FormArray([]),
          is_updated: [false, [Validators.required]],
        })])
    });
  }

  about(): FormArray {
    return this.aboutForm.get("about") as FormArray
  }

  newabout(): FormGroup {
    return this.fb.group({
      heading: ['-', [Validators.required]],
      is_updated: [true, [Validators.required]],
      description_datas: new FormArray([
        this.fb.group({
          text: '',
        })
      ]),
    })
  }

  addabout() {
    this.about().push(this.newabout());
  }

  removeabout(i: number) {
    this.aboutForm.value.about[i].is_deleted = true;
    if (this.aboutForm.value.about[i].hasOwnProperty("id")) this.deletedabout.push(this.aboutForm.value.about[i]);
    this.about().removeAt(i);
  }


  onSubmit(form) {
    if (form.valid) {
      this.isUpdating = true;
      this.deletedabout.forEach(ae => {
        ae.is_updated = false;
        form.value.about.push(ae);
      })


      form.value.about.forEach(ae => {
        ae.category = this.category_id;
      })
      this.api.setAboutVisa(form.value.about).subscribe(
        () => {
          this.onSetSuccess();
        })
    } else {
      this.toastr.warning("Fill all fields and Save!");
    }
  }

  onSetSuccess() {
    this.toastr.success("Content Updated Successfully!");
    this.getAbout();
    this.hasChanges = false;
    this.isUpdating = false;
  }


  getAbout() {
    this.api.getAboutVisa(this.category_id).subscribe(
      (result: any) => {
        this.setData(result);
      })
  }

  setData(result) {
    this.aboutForm = this.fb.group({
      about: new FormArray([]),
    });
    result.forEach(ao => {
      (this.aboutForm.get('about') as FormArray).push(
        this.fb.group({
          id: [ao.id, [Validators.required]],
          heading: [ao.heading || '-', [Validators.required]],
          is_updated: [false],
          description_datas: this.setDescriptions(ao),
        })
      );
      this.deletedabout = [];
    });
  }

  dataChanged(i) {
    this.aboutForm.value.about[i].is_updated = true;
  }

  setDescriptions(x) {
    let arr = new FormArray([])
    x.description.forEach(y => {
      arr.push(this.fb.group({
        text: y
      }))
    })
    return arr;
  }

  addNewDescription(control) {
    control.push(
      this.fb.group({
        text: ['']
      }));
  }

  deleteDescription(control, index) {
    control.removeAt(index)
  }

}
