import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormGroup, FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HomeManagementService } from 'src/app/core/services/home-management.service';
import { ToastrService } from 'ngx-toastr';
import { OtherPagesService } from 'src/app/core/services/other-pages.service';
import { UploadGalleryComponent } from 'src/app/shared/ui/upload-gallery/upload-gallery.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-interactive-banner',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  templateUrl: './interactive-banner.component.html',
  styleUrls: ['./interactive-banner.component.scss']
})
export class InteractiveBannerComponent {
  headerForm: FormGroup;
  fileToUpload: File | null = null;
  hasChanges = false;
  isUpdating = false;
  isSubmitted = false;
  @Input() page;
  isNew = false;
  publishPage;

  constructor(private fb: FormBuilder,
    private api: HomeManagementService, private toastr: ToastrService,
    private otherApi: OtherPagesService, private modalService: NgbModal) {
  }

  ngOnInit(): void {
    this.resetForm();
    this.getData();
  }

  resetForm(data?: any) {
    console.log("set data", data);
    this.headerForm = this.fb.group({
      id: [data ? data.id : null],
      interactive_banner: [data ? data.interactive_banner : ''],
      interactive_banner_mob: [data ? data.interactive_banner_mob : ''],
      interactive_banner_link: [data ? data.interactive_banner_link : ''],
    });
    this.onChanges();
  }


  onChanges(): void {
    this.headerForm.valueChanges.subscribe(() => {
      Object.keys(this.headerForm.controls).map((key) => {
        if (this.headerForm.get(key).dirty) this.hasChanges = true;
      })
    });
  }

  getData() {
    this.otherApi.getInteractiveBanner().subscribe(
      (result: any) => {
        this.resetForm(result[0]);
        if (result.length == 0) this.isNew = true;
        else { this.isNew = false; };
      },
    )
  }

  onSubmit(form) {
    if (form.valid) {
      this.isUpdating = true;
      if (this.isNew) {
        this.otherApi.postInteractiveBanner(form.value).subscribe(
          (result: any) => {
            this.resetForm(result);
            this.toastr.success("Content Added Successfully!");
            this.hasChanges = false;
            this.isUpdating = false;
            this.isNew = false;
          },
          () => {
            this.isUpdating = false;
          }
        )
      }
      else {
        this.otherApi.setInteractiveBanner(form.value.id, form.value).subscribe(
          (result: any) => {
            this.resetForm(result);
            this.toastr.success("Content Updated Successfully!");
            this.hasChanges = false;
            this.isUpdating = false;
          },
          () => {
            this.isUpdating = false;
          }
        )
      }
    } else {
      this.toastr.warning("Fill all fields!");
    }
  }

  openUploadModal(field) {
    const modalRef = this.modalService.open(UploadGalleryComponent,
      {
        size: 'xl',
        scrollable: true,
        windowClass: 'modal-big-lg',
        keyboard: false,
        backdrop: 'static'
      });
    modalRef.componentInstance.uploadDetails = {};
    modalRef.result.then((result) => {
      if (result.status) {
        this.headerForm.get(field).setValue(result.image);
      }
    });
  }
}
