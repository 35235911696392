import { Component, Input } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import { V3ServicesService } from 'src/app/core/services/v3-services.service';
import { ToastrService } from 'ngx-toastr';
import { UploadGalleryComponent } from 'src/app/shared/ui/upload-gallery/upload-gallery.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-product-benefits',
  templateUrl: './product-benefits.component.html',
  styleUrls: ['./product-benefits.component.scss']
})
export class ProductBenefitsComponent {

  benefitForm: FormGroup;
  hasChanges = false;
  isSubmitted = false;

  @Input() country;
  @Input() page;
  @Input() product;

  isUpdating = false;
  deletedBenefit = [];

  constructor(private fb: FormBuilder,
    private toastr: ToastrService,
    private api: V3ServicesService,
    private modalService: NgbModal) { }

  ngOnInit(): void {
    this.resetForm();
    this.getProductBenefits();
  }

  resetForm() {
    this.benefitForm = this.fb.group({
      benefits: this.fb.array([])
    });
  }

  getProductBenefits() {
    this.api.getProductBenefits(this.product).subscribe(
      (result: any) => {
        this.setData(result.benefits);
      })
  }

  setData(result) {
    this.benefitForm = this.fb.group({
      benefits: new FormArray([]),
    });
    result.forEach(ao => {
      (this.benefitForm.get('benefits') as FormArray).push(
        this.fb.group({
          id: [ao.id],
          benefit: [ao.benefit, [Validators.required]],
          icon: [ao.icon, [Validators.required]],
          is_updated: [false],
          is_new: [false],
        })
      );
    });
  }

  benefit(): FormArray {
    return this.benefitForm.get("benefits") as FormArray
  }

  newbenefit(): FormGroup {
    return this.fb.group({
      benefit: ['', [Validators.required]],
      icon: ['', [Validators.required]],
      is_updated: [false],
      is_new: [true],
    })
  }

  addbenefit() {
    this.benefit().push(this.newbenefit());
  }

  removebenefit(i: number) {
    this.isSubmitted = false;
    this.benefitForm.value.benefits[i].is_deleted = true;
    if (this.benefitForm.value.benefits[i].hasOwnProperty("id")) this.deletedBenefit.push(this.benefitForm.value.benefits[i]);
    this.benefit().removeAt(i);
  }

  dataChanged(i) {
    let ae = this.benefitForm.value.benefits[i];
    if (ae.is_new) {
      delete ae.is_updated;
    } else {
      ae.is_updated = true;
      delete ae.is_new;
    }

  }

  onSubmit(form) {

    if (form.valid) {
      this.isUpdating = true;
      this.deletedBenefit.forEach(ae => {
        delete ae.is_updated;
        delete ae.is_new;
        form.value.benefits.push(ae);
      })

      this.api.setProductBenefits({
        product: this.product,
        benefits: form.value.benefits
      }).subscribe(
        () => {
          this.toastr.success("Content Updated Successfully!");
          this.getProductBenefits();
          this.hasChanges = false;
          this.isUpdating = false;
        },
        () => {
          this.isUpdating = false;
        })
    }
  }

  openUploadModal(i, field) {
    const modalRef = this.modalService.open(UploadGalleryComponent,
      {
        size: 'xl',
        scrollable: true,
        windowClass: 'modal-big-lg',
        keyboard: false,
        backdrop: 'static'
      });
    modalRef.componentInstance.uploadDetails = {};
    modalRef.result.then((result) => {
      if (result.status) {
        this.benefitForm.get(['benefits', i, field]).setValue(result.image);
        this.benefitForm.get(['benefits', i, 'is_updated']).setValue(!this.benefitForm.get(['benefits', i, 'is_new']).value);
      }
    });
  }
}
